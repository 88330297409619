import { useMemo } from 'react';
import { Space, Table } from 'antd';
import { Answers } from './OhatResultGraph';
import { Question } from '../../../constants/question';
import { OhatStatus } from '../../../constants/status';
import {
  getScoreTableRowHalf1,
  getScoreTableRowHalf2,
} from '../../../utils/ohat-score-utils';

const renderTitle = (q: Question) => {
  return (
    <Space.Compact direction="vertical">
      <span style={{ fontSize: '11px' }}>{q.key}</span>
      <span style={{ fontSize: '12px' }}>{q.label}</span>
    </Space.Compact>
  );
};

const renderColumn = (q: Question, i: number) => {
  return {
    title: renderTitle(q),
    dataIndex: i,
    key: q.key,
    width: '20%',
    render: (text: string) => {
      if (text === OhatStatus.bad.label) {
        return (
          <span style={{ color: 'orange', fontWeight: 600, fontSize: '12px' }}>
            {text}
            <br />
            {`${OhatStatus.bad.score}pt`}
          </span>
        );
      } else if (text === OhatStatus.diseased.label) {
        return (
          <span style={{ color: 'red', fontWeight: 600, fontSize: '12px' }}>
            {text}
            <br />
            {`${OhatStatus.diseased.score}pt`}
          </span>
        );
      } else {
        return (
          <span style={{ color: 'blue', fontSize: '12px' }}>
            {text}
            <br />
            {`${OhatStatus.fine.score}pt`}
          </span>
        );
      }
    },
  };
};

const dataColumns1 = [Question.Q1, Question.Q2, Question.Q3, Question.Q4].map(
  (q, i) => renderColumn(q, i),
);

const dataColumns2 = [Question.Q5, Question.Q6, Question.Q7, Question.Q8].map(
  (q, i) => renderColumn(q, i),
);

const renderLabelColumn = (text: string) => {
  if (text === '今回') {
    return <span style={{ fontWeight: 600, fontSize: '12px' }}>{text}</span>;
  } else {
    const [label, date] = text.split('.');
    return (
      <Space.Compact direction="vertical">
        <span style={{ fontWeight: 200, fontSize: '12px' }}>{label}</span>
        <span style={{ fontWeight: 200, fontSize: '10px' }}>{date}</span>
      </Space.Compact>
    );
  }
};

type Props = {
  answers: Answers;
  oldAnswers: Answers[];
};
export default function OhatResultVerticalTable(props: Props) {
  const { answers, oldAnswers } = props;

  const data1 = useMemo(() => {
    const [first, latest] = oldAnswers;
    return [
      Object.assign(
        { label: '今回' },
        getScoreTableRowHalf1('thisScore', answers),
      ),
      latest &&
        Object.assign(
          { label: `前回.${latest.evaluatedAt}` },
          getScoreTableRowHalf1('latestScore', latest),
        ),
      first &&
        Object.assign(
          { label: `初回.${first.evaluatedAt}` },
          getScoreTableRowHalf1('firstScore', first),
        ),
    ].filter(Boolean);
  }, [answers, oldAnswers]);

  const data2 = useMemo(() => {
    const [first, latest] = oldAnswers;
    return [
      Object.assign(
        { label: '今回' },
        getScoreTableRowHalf2('thisScore', answers),
      ),
      latest &&
        Object.assign(
          { label: `前回.${latest.evaluatedAt}` },
          getScoreTableRowHalf2('latestScore', latest),
        ),
      first &&
        Object.assign(
          { label: `初回.${first.evaluatedAt}` },
          getScoreTableRowHalf2('firstScore', first),
        ),
    ].filter(Boolean);
  }, [answers, oldAnswers]);

  return (
    <div style={{ margin: 20 }}>
      <Table
        dataSource={data1}
        columns={
          data1.length === 1
            ? dataColumns1
            : [
                {
                  title: '',
                  dataIndex: 'label',
                  key: 'label',
                  width: '10%',
                  rowScope: 'row',
                  render: renderLabelColumn,
                },
                ...dataColumns1,
              ]
        }
        bordered
        pagination={false}
        className={'ant-slim-table'}
        size="small"
        style={{ paddingBottom: 3 }}
      />
      <Table
        dataSource={data2}
        columns={
          data2.length === 1
            ? dataColumns2
            : [
                {
                  title: '',
                  dataIndex: 'label',
                  key: 'label',
                  width: '10%',
                  rowScope: 'row',
                  render: renderLabelColumn,
                },
                ...dataColumns2,
              ]
        }
        bordered
        pagination={false}
        className={'ant-slim-table'}
        size="small"
      />
    </div>
  );
}
