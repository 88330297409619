import { Layout, Menu, Typography } from 'antd';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import { Outlet, useNavigate, ScrollRestoration } from 'react-router-dom';
import { useGAPageTracking } from '../../hooks/useGAPageTracking';
import { Suspense } from 'react';
import { Loading } from '../components/common/Loading';
import ErrorBoundary from '../components/common/ErrorBoundary';
import { useAuth } from '../../hooks/useAuth';
import { MenuOutlined } from '@ant-design/icons';
import useMobile from '../../hooks/useMobile';

export const AccountLayout = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  useGAPageTracking();

  const items = [
    {
      key: '1',
      label: isMobile() ? '' : 'メニュー',
      icon: <MenuOutlined />,
      children: [
        {
          label: 'TOPページ',
          key: 'menu1',
          onClick: () => (window.location.href = `https://ohat.jp`),
        },
        {
          label: '管理ページ',
          key: 'menu2',
          onClick: () => (window.location.href = `https://app.ohat.jp`),
        },
        {
          label: '使い方ページ',
          key: 'menu3',
          onClick: () => (window.location.href = `https://ohat.jp/howto`),
        },
        {
          label: 'ログアウト',
          key: 'menu4',
          onClick: () => auth.signOutCognito(),
        },
      ],
    },
  ];

  return (
    <Layout
      style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <ScrollRestoration />
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: 'white',
          padding: 0,
          boxShadow: '0 1px 5px 0 rgba(0, 0, 0, .1)',
        }}
      >
        <div style={{ flex: 1 }}></div>
        <img
          src={`${process.env.PUBLIC_URL}/logo.svg`}
          alt="Logo"
          height={50}
          onClick={() => navigate('/')}
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            cursor: 'pointer',
          }}
        />
        <div style={{ flex: 1 }}></div>
        <div style={{ display: 'flex' }}>
          <Menu mode="horizontal" items={items} />
        </div>
      </Header>
      <Layout>
        <Content
          style={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start', // 追加: 縦方向の配置を上端に揃える
            padding: 16,
            background: '#F5FAFF',
          }}
        >
          <ErrorBoundary>
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        </Content>
      </Layout>
      <Footer style={{ textAlign: 'center', background: 'rgb(237 237 237)' }}>
        Copyright © メディバンクス株式会社 All Rights Reserved.
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
          <a
            href="https://www.ohat.jp/%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84"
            target="_blank"
            rel="noreferrer"
          >
            <Typography.Text
              style={{
                paddingTop: 4,
                fontSize: 14,
                textAlign: 'center',
                textDecorationLine: 'underline',
              }}
            >
              利用規約
            </Typography.Text>
          </a>
          <a
            href="https://medi-banx.com/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            <Typography.Text
              style={{
                paddingTop: 4,
                fontSize: 14,
                textAlign: 'center',
                textDecorationLine: 'underline',
              }}
            >
              個人情報の取り扱い
            </Typography.Text>
          </a>
          <a
            href="https://medi-banx.com/contact/"
            target="_blank"
            rel="noreferrer"
          >
            <Typography.Text
              style={{
                paddingTop: 4,
                fontSize: 14,
                textAlign: 'center',
                textDecorationLine: 'underline',
              }}
            >
              お問い合わせ
            </Typography.Text>
          </a>
        </div>
      </Footer>
    </Layout>
  );
};
