import { Button, Row } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

type Props = {
  onBack: () => void;
};
export default function OhatCheckHeader(props: Props) {
  const { onBack } = props;
  return (
    <Row justify="space-between" align="middle">
      <Button shape="round" icon={<LeftOutlined />} onClick={onBack} />
      {/* <Progress
        percent={(indexStep / ALL_QUESTIONS.length) * 100}
        format={(_) => (
          <Text type="secondary">{`${indexStep} / ${ALL_QUESTIONS.length}`}</Text>
        )}
        steps={ALL_QUESTIONS.length}
        size={[20, 10]}
        strokeColor="#03007E"
      /> */}
    </Row>
  );
}
