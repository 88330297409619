import React from 'react';
import { Navigate } from 'react-router-dom';

// PropsとStateの型を定義します
interface ErrorBoundaryProps {
  children: React.ReactNode; // childrenはReactNode型
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  // コンストラクターでstateの型を初期化
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
    // eventHandlerを定義し、updateErrorメソッドにバインド
    this.eventHandler = this.updateError.bind(this);
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  // updateErrorメソッドを定義
  updateError() {
    this.setState({ hasError: true });
  }

  componentDidMount() {
    window.addEventListener('error', this.eventHandler);
    window.addEventListener('unhandledrejection', this.eventHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.eventHandler);
    window.removeEventListener('unhandledrejection', this.eventHandler);
  }

  render() {
    if (this.state.hasError) {
      return <Navigate to="/error" replace />;
    }
    return this.props.children;
  }

  // eventHandlerプロパティの型を宣言
  private eventHandler: () => void;
}

export default ErrorBoundary;
