import { BaseConstants } from './baseConstants';

export class Gender extends BaseConstants {
  static readonly male = new Gender('male', '男性');
  static readonly female = new Gender('female', '女性');

  private constructor(
    public readonly key: string,
    public readonly label: string,
  ) {
    super();
  }

  toString() {
    return this.key;
  }
}
