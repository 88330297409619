import { useAuth } from '../../../hooks/useAuth';
import { Navigate, Outlet } from 'react-router-dom';
export const PrivateAdminRoute: React.FC = () => {
  const { isAuthenticated, account } = useAuth();

  return isAuthenticated && account?.isAdmin ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" />
  );
};
