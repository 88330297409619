import { Layout, Menu } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { Suspense } from 'react';
import { Outlet, ScrollRestoration, useNavigate } from 'react-router-dom';
import { Loading } from '../components/common/Loading';
import ErrorBoundary from '../components/common/ErrorBoundary';

export const AdminLayout = () => {
  const navigate = useNavigate();

  return (
    <Layout
      style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <ScrollRestoration />
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: 'white',
          boxShadow: '0 1px 5px 0 rgba(0, 0, 0, .1)',
        }}
      >
        <Menu mode="horizontal">
          <Menu.Item onClick={() => navigate('/admin/')}>施設一覧</Menu.Item>
          <Menu.Item onClick={() => navigate('/admin/organizations/rate')}>
            施設割合
          </Menu.Item>
        </Menu>
        <Menu mode="horizontal">
          <Menu.Item onClick={() => navigate('/signout')}>ログアウト</Menu.Item>
        </Menu>
      </Header>
      <Layout>
        <Content
          style={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start', // 追加: 縦方向の配置を上端に揃える
            padding: 16,
            background: '#F5FAFF',
          }}
        >
          <ErrorBoundary>
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        </Content>
      </Layout>
    </Layout>
  );
};
