import { useMemo } from 'react';
import { Space, Table } from 'antd';
import { Answers } from './OhatResultGraph';
import { Question } from '../../../constants/question';
import { OhatStatus } from '../../../constants/status';
import { getScoreTableRow } from '../../../utils/ohat-score-utils';

const renderTitle = (q: Question) => {
  return (
    <Space.Compact direction="vertical">
      <span style={{ fontSize: '11px' }}>{q.key}</span>
      <span style={{ fontSize: '12px' }}>{q.label}</span>
    </Space.Compact>
  );
};
const dataColumns = Question.getAll().map((q, i) => {
  return {
    title: renderTitle(q),
    dataIndex: i,
    key: q.key,
    width: '10%',
    render: (text: string) => {
      if (text === OhatStatus.bad.label) {
        return (
          <span style={{ color: 'orange', fontWeight: 600, fontSize: '12px' }}>
            {text}
            <br />
            {`${OhatStatus.bad.score}pt`}
          </span>
        );
      } else if (text === OhatStatus.diseased.label) {
        return (
          <span style={{ color: 'red', fontWeight: 600, fontSize: '12px' }}>
            {text}
            <br />
            {`${OhatStatus.diseased.score}pt`}
          </span>
        );
      } else {
        return (
          <span style={{ color: 'blue', fontWeight: 600, fontSize: '12px' }}>
            {text}
            <br />
            {`${OhatStatus.fine.score}pt`}
          </span>
        );
      }
    },
  };
});

const renderLabelColumn = (text: string) => {
  if (text === '今回') {
    return <span style={{ fontWeight: 600, fontSize: '12px' }}>{text}</span>;
  } else {
    const [label, date] = text.split('.');
    return (
      <Space.Compact direction="vertical">
        <span style={{ fontWeight: 200, fontSize: '12px' }}>{label}</span>
        <span style={{ fontWeight: 200, fontSize: '10px' }}>{date}</span>
      </Space.Compact>
    );
  }
};

type Props = {
  answers: Answers;
  oldAnswers: Answers[];
};
export default function OhatResultTable(props: Props) {
  const { answers, oldAnswers } = props;

  const data = useMemo(() => {
    const [first, latest] = oldAnswers;
    return [
      Object.assign({ label: '今回' }, getScoreTableRow('thisScore', answers)),
      latest &&
        Object.assign(
          { label: `前回.${latest.evaluatedAt}` }, // . でつなげる
          getScoreTableRow('latestScore', latest),
        ),
      first &&
        Object.assign(
          { label: `初回.${first.evaluatedAt}` },
          getScoreTableRow('firstScore', first),
        ),
    ].filter(Boolean); //nullを除去
  }, [answers, oldAnswers]);

  return (
    <Table
      dataSource={data}
      columns={
        data.length === 1
          ? dataColumns
          : [
              {
                title: '',
                dataIndex: 'label',
                key: 'label',
                width: '10%',
                rowScope: 'row',
                render: renderLabelColumn,
              },
              ...dataColumns,
            ]
      }
      bordered
      pagination={false}
      className={'ant-slim-table'}
      size="small"
    />
  );
}
