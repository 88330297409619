import {
  getPatientFindOhatScoresQueryKey,
  useAccountUpdateActivity,
  useOhatScoreRemove,
  usePatientFindOhatScoresSuspense,
} from '../../../libs/ohat-api';
import { Card, Col, DatePicker, Row, Space } from 'antd';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { OhatScoresGraph } from '../../components/OhatScoresGraph';
import {
  getDateFromYear,
  getEndYearOf,
  getNow,
  getNowYear,
  getStartYearOf,
} from '../../../utils/date';
import { Link } from 'react-router-dom';
import { PatientDetailPanel } from '../../components/PatientDetailPanel';
import { PatientPageBreadCrumb } from '../../components/breadcrumb/PatientPageBreadCrumb';
import { OhatScoresTable } from '../../components/OhatScoresTable';
import { useAuth } from '../../../hooks/useAuth';
import { useQueryClient } from '@tanstack/react-query';
import { useGAEvent } from '../../../hooks/useGAEvent';

export function PatientDetailPage() {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const param = useParams();
  const [searchParams] = useSearchParams();
  const year = searchParams.get('year') ?? getNowYear();
  const { mutateAsync: removeOhatScore } = useOhatScoreRemove();
  const queryClient = useQueryClient();
  const { mutateAsync } = useAccountUpdateActivity();
  const { data } = usePatientFindOhatScoresSuspense(param.id ?? '', year);
  const { sendClickEvent } = useGAEvent();

  const onDeleteOhatScore = async (scoreId: string) => {
    await removeOhatScore(
      { id: scoreId },
      {
        onSuccess(_) {
          queryClient.invalidateQueries({
            queryKey: getPatientFindOhatScoresQueryKey(param.id!, year),
          });
        },
      },
    );
  };

  const addQueryParams = (year: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('year', year); // 追加したいクエリパラメータを設定
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const onChange = (date: any) => {
    if (date['$y']) {
      addQueryParams(date['$y']);
    }
  };

  const onClickOhatButton = async () => {
    if (auth.account) await mutateAsync({ id: auth.account.id });
    sendClickEvent('OHAT評価');
    navigate(`check`);
  };

  const disabledFutureYear = (current: any) => {
    // 現在の年より未来の年を無効にする
    return current && current > getEndYearOf(getNow());
  };

  return (
    <Row gutter={[16, 8]} style={{ flex: 1, maxWidth: 1200 }}>
      <Col md={24} xs={24}>
        <PatientPageBreadCrumb />
      </Col>
      <Col md={8} xs={24}>
        <Card title="患者情報" extra={<Link to={'edit'}>編集</Link>}>
          {data && data.patient && (
            <PatientDetailPanel
              patient={data.patient}
              onClick={() => onClickOhatButton()}
              ohatScoresCount={data.ohatScoresCount}
            />
          )}
        </Card>
      </Col>
      <Col md={16} xs={24}>
        <Card
          title="評価履歴"
          extra={
            <DatePicker
              onChange={onChange}
              placeholder="対象年"
              defaultValue={
                year ? getStartYearOf(getDateFromYear(year)) : getNow()
              }
              picker="year"
              format={'YYYY 年'}
              allowClear={false}
              disabledDate={disabledFutureYear} // 未来の日付を無効にする
              inputReadOnly
            />
          }
        >
          <Space
            direction="vertical"
            size="middle"
            style={{
              display: 'flex',
            }}
          >
            <OhatScoresGraph
              scores={data.patient.ohatScores ?? []}
              year={year}
            />
            <OhatScoresTable
              scores={data.patient.ohatScores ?? []}
              onDelete={onDeleteOhatScore}
            />
          </Space>
        </Card>
      </Col>
    </Row>
  );
}
