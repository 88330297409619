import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

export const HomePageBreadCrumb = () => {
  return (
    <Breadcrumb
      items={[
        {
          title: (
            <>
              <HomeOutlined />
              <span>管理ページ</span>
            </>
          ),
        },
      ]}
    />
  );
};
