import { Card, Col, Row } from 'antd';
import OhatResultPanel from '../../components/check/OhatResultPanel';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  useOhatScoreFindFirstAndLatestSuspense,
  useOhatScoreFindOneSuspense,
  usePatientFindOneSuspense,
} from '../../../libs/ohat-api';
import {
  extractAnswers,
  getInitAnswers,
} from '../../../utils/ohat-score-utils';

export default function ScoreDetailPage() {
  const param = useParams();
  const { data: patient } = usePatientFindOneSuspense(param.patientId ?? '');
  const { data: score } = useOhatScoreFindOneSuspense(param.id!);
  const { data: oldScores } = useOhatScoreFindFirstAndLatestSuspense(
    param.patientId!,
    {
      baseDate: score.evaluatedAt,
    },
  );

  const answers = useMemo(() => {
    if (!score) return getInitAnswers();
    return extractAnswers(score);
  }, [score]);

  return (
    <Row
      gutter={[16, 8]}
      style={{ flex: 1, justifyContent: 'center', maxWidth: 1200 }}
    >
      <Col lg={14} md={18} sm={20} xs={24}>
        <Card>
          <OhatResultPanel
            answers={answers}
            oldOhatScores={oldScores}
            isGuestMode={false}
            patient={patient}
          />
        </Card>
      </Col>
    </Row>
  );
}
