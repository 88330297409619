const AuthConfig = {
  Cognito: {
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID as string,
    userPoolClientId: process.env
      .REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID as string,
    identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID as string,
  },
};

export default AuthConfig;
