import { AxiosError } from 'axios';
import Axios, { AxiosRequestConfig } from 'axios';
import { fetchAuthSession } from '@aws-amplify/auth';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

async function getToken() {
  const session = await fetchAuthSession();
  return session.tokens?.accessToken.toString() ?? '';
}

AXIOS_INSTANCE.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// add a second `options` argument here if you want to pass extra options to each generated query
export const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data);

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>;

export type BodyType<BodyData> = BodyData;
