import ReactGA from 'react-ga4';

export const useGAEvent = () => {
  const sendClickEvent = (eventName: string) => {
    ReactGA.event({
      category: 'UserAction',
      action: eventName,
    });
  };

  return { sendClickEvent };
};
