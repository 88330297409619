import { BaseConstants } from './baseConstants';

export class OhatStatus extends BaseConstants {
  static readonly fine = new OhatStatus('fine', '健康的', 0);
  static readonly bad = new OhatStatus('bad', 'やや病的', 1);
  static readonly diseased = new OhatStatus('diseased', '病的', 2);

  private constructor(
    public readonly key: string,
    public readonly label: string,
    public readonly score: number,
  ) {
    super();
  }

  static getByScore(score: number) {
    return this.getAll().find((v) => v.score === score);
  }
}
