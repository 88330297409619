import { BaseConstants } from './baseConstants';

export class OrganizationType extends BaseConstants {
  static readonly dentalClinicWithHomeVisit = new OrganizationType(
    'dentalClinicWithHomeVisit',
    '歯科医院(訪問歯科対応あり)',
  );
  static readonly dentalClinicWithoutHomeVisit = new OrganizationType(
    'dentalClinicWithoutHomeVisit',
    '歯科医院(訪問歯科対応なし)',
  );
  static readonly universityHospital = new OrganizationType(
    'universityHospital',
    '大学病院',
  );
  static readonly acuteCareHospital = new OrganizationType(
    'acuteCareHospital',
    '急性期病院',
  );
  static readonly generalHospital = new OrganizationType(
    'generalHospital',
    '総合病院(ケアミックス病院)',
  );
  static readonly convalescentHospital = new OrganizationType(
    'convalescentHospital',
    '回復期病院',
  );
  static readonly longTermCareHospital = new OrganizationType(
    'longTermCareHospital',
    '療養型病院',
  );
  static readonly nursingHome = new OrganizationType(
    'nursingHome',
    '介護施設(特養・老健・有料老人ホーム)',
  );
  static readonly homeNursingStation = new OrganizationType(
    'homeNursingStation',
    '訪問看護ステーション',
  );
  static readonly clinic = new OrganizationType('clinic', '診療所');
  static readonly publicHealthCenter = new OrganizationType(
    'publicHealthCenter',
    '区(市)役所、保健所、地域包括支援センター',
  );
  static readonly others = new OrganizationType('others', 'その他');

  private constructor(
    public readonly key: string,
    public readonly label: string,
  ) {
    super();
  }

  toString() {
    return this.key;
  }
}
