import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { App, Button, Card, Flex } from 'antd';
import { Link } from 'react-router-dom';
import { SignInForm } from './form/SignInForm';

export function SignInPage() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { message } = App.useApp();

  useEffect(() => {
    if (auth.isAuthenticated) navigate({ pathname: '/' });
  }, [auth, navigate]);

  const onSubmit = async (email: string, password: string) => {
    const sub = await auth.signInCognito(email, password);
    if (sub) {
      navigate({ pathname: '/' });
    } else {
      message.error('ログインに失敗しました');
    }
  };

  return (
    <Flex
      vertical
      style={{
        maxWidth: 500,
      }}
    >
      <img
        src={`${process.env.PUBLIC_URL}/logo.svg`}
        alt="Logo"
        style={{ alignSelf: 'center', marginBottom: 25 }}
      />
      <Card
        styles={{
          body: {
            padding: 0,
            overflow: 'hidden',
          },
        }}
      >
        <Flex
          vertical
          align="flex-end"
          justify="space-between"
          style={{
            minWidth: 320,
            padding: 26,
          }}
        >
          <SignInForm onSubmit={onSubmit} buttonText="ログイン" />
          <Button
            size="large"
            onClick={() => navigate({ pathname: '/signup' })}
            block
          >
            新規登録
          </Button>
          <Link to={'/reset-password'} style={{ marginTop: 15 }}>
            パスワードを忘れた場合
          </Link>
          <Link to={'/reset-password'} style={{ marginTop: 5 }}></Link>
          <a href="https://ohat.jp/" target="_blank" rel="noreferrer">
            OHATの使い方
          </a>
        </Flex>
      </Card>
    </Flex>
  );
}
