import { Avatar, Button, Flex, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  BankOutlined,
  IdcardOutlined,
  TeamOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { Organization } from '@models/organization';
import useMobile from '../../hooks/useMobile';
import { OrganizationType } from '../../constants/orgType';

export type Props = {
  organization: Organization;
};
export const OrganizationDetailPanel = (props: Props) => {
  const { organization } = props;
  const navigate = useNavigate();
  const { isMobile } = useMobile();

  return (
    <Flex
      vertical
      align="center"
      gap={isMobile() ? 'small' : 'large'}
      justify="start"
    >
      <Avatar
        size={{
          xs: 60,
          sm: 60,
          md: 80,
          lg: 80,
          xl: 80,
          xxl: 80,
        }}
        icon={<BankOutlined />}
      />
      <Typography.Text
        style={{ fontSize: 20, fontWeight: 'bold' }}
      >{`${organization.name}`}</Typography.Text>
      <Flex vertical align="center">
        <Row style={{ color: 'gray' }}>
          <IdcardOutlined />
          <Typography.Text style={{ marginLeft: 10 }}>{`${
            OrganizationType.getByKey(organization.type).label
          }`}</Typography.Text>
        </Row>
        <Row style={{ color: 'gray' }}>
          <TeamOutlined />
          <Typography.Text style={{ marginLeft: 10 }}>
            {`登録患者数 ${organization.patients?.length ?? 0}人`}
          </Typography.Text>
        </Row>
      </Flex>

      <Button
        shape="round"
        size="large"
        type="primary"
        onClick={() => navigate(`/patients/create`)}
        icon={<UserAddOutlined />}
      >
        患者追加
      </Button>
    </Flex>
  );
};
