import { usePatientCreate } from '../../../libs/ohat-api';
import { Card, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { CreatePatientDto } from '@models/createPatientDto';
import { PatientForm } from './form/PatientForm';
import { UpdatePatientDto } from '@models/updatePatientDto';

export function CreatePatientPage() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { mutateAsync: createPatient } = usePatientCreate();

  const onSubmit = async (values: CreatePatientDto | UpdatePatientDto) => {
    const param = Object.assign(values, {
      organization: { connect: { id: auth.account?.organizationId ?? '' } },
    }) as CreatePatientDto;
    await createPatient({ data: param });
    navigate('/');
  };

  return (
    <Row gutter={[16, 8]} style={{ flex: 1, justifyContent: 'center' }}>
      <Col lg={8} md={12} sm={16} xs={24}>
        <Card title="患者登録">
          <PatientForm onSubmit={onSubmit} onCancel={() => navigate('/')} />
        </Card>
      </Col>
    </Row>
  );
}
