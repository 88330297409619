import {
  useAccountUpdate,
  useOrganizationCreate,
} from '../../../libs/ohat-api';
import { Card, Col, Row } from 'antd';
import { CreateOrganizationDto } from '@models/createOrganizationDto';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { UpdateOrganizationDto } from '@models/updateOrganizationDto';
import { OrganizationForm } from './form/OrganizationForm';

export function CreateOrganizationPage() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { mutateAsync: createOrganization } = useOrganizationCreate();
  const { mutateAsync: updateAccount } = useAccountUpdate();

  const onSubmit = async (
    values: CreateOrganizationDto | UpdateOrganizationDto,
  ) => {
    const param = values as CreateOrganizationDto;
    const org = await createOrganization({ data: param });
    const account = await updateAccount({
      id: auth.account!.id,
      data: { organization: { connect: { id: org.id } } },
    });
    await auth.fetchAccount(account.sub);
    navigate('/');
  };

  return (
    <Row gutter={[16, 8]} style={{ flex: 1, justifyContent: 'center' }}>
      <Col lg={8} md={12} sm={16} xs={24}>
        <Card title="事業所登録">
          <OrganizationForm onSubmit={onSubmit} />
        </Card>
      </Col>
    </Row>
  );
}
