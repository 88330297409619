import { Button, Popconfirm, Table } from 'antd';
import { useMemo } from 'react';
import { OhatScore } from '@models/ohatScore';
import { format } from '../../utils/date';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';

export type Props = {
  scores: OhatScore[];
  onDelete: (id: string) => {};
};
export const OhatScoresTable = (props: Props) => {
  const { scores, onDelete } = props;
  const navigate = useNavigate();

  const onConfirm = async (id: string) => {
    await onDelete(id);
  };

  const scoreData = useMemo(() => {
    return scores.map((v) => {
      return {
        key: v.id,
        date: format(v.evaluatedAt, 'MM/DD HH:mm'),
        dateRaw: v.evaluatedAt,
        score: v.q1 + v.q2 + v.q3 + v.q4 + v.q5 + v.q6 + v.q7 + v.q8,
      };
    });
  }, [scores]);

  const columns = [
    {
      title: '日時',
      dataIndex: 'date',
      key: 'date',
      // defaultSortOrder: 'descend' as SortOrder,
      // sorter: (a: any, b: any) => a.localeCompare(b),
    },
    {
      title: 'スコア',
      dataIndex: 'score',
      key: 'score',
    },
    {
      title: '',
      render: (_: any, record: any) => (
        <span
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button
            type="default"
            size="large"
            onClick={() => navigate(`ohat-scores/${record.key}`)}
            style={{ marginRight: 20 }}
          >
            確認
          </Button>
          <Popconfirm
            title="削除しますか？"
            okText="Yes"
            cancelText="No"
            onConfirm={() => onConfirm(record.key)}
          >
            <Button danger shape="circle" icon={<DeleteOutlined />} />
          </Popconfirm>
        </span>
      ),
      key: 'action',
    },
  ];

  return (
    <Table
      dataSource={scoreData}
      columns={columns}
      pagination={{
        pageSize: 5,
      }}
    />
  );
};
