import { Patient } from '@models/patient';
import { Avatar, Button, Flex, Row, Typography } from 'antd';
import { getFirstChar } from '../../utils/string';
import {
  IdcardOutlined,
  LineChartOutlined,
  MonitorOutlined,
} from '@ant-design/icons';
import { PatientCareLevel } from '../../constants/careLevel';
import useMobile from '../../hooks/useMobile';
import { IndependentLifeLevel } from '../../constants/independentLifeLevel';

export type Props = {
  patient: Patient;
  ohatScoresCount: number;
  onClick: () => {};
};
export const PatientDetailPanel = (props: Props) => {
  const { patient, ohatScoresCount, onClick } = props;
  const { isMobile } = useMobile();

  const lifeLevel = IndependentLifeLevel.getByKey(patient.independentLifeLevel);
  const careLevel = PatientCareLevel.getByKey(patient.careLevel);
  return (
    <Flex
      vertical
      align="center"
      gap={isMobile() ? 'small' : 'large'}
      justify="start"
    >
      <Avatar
        size={{
          xs: 60,
          sm: 60,
          md: 80,
          lg: 80,
          xl: 80,
          xxl: 80,
        }}
      >
        {getFirstChar(patient.name ?? '')}
      </Avatar>
      <Typography.Text style={{ fontSize: 20, fontWeight: 'bold' }}>
        {patient.age ? `${patient.name} (${patient.age}歳)` : `${patient.name}`}
      </Typography.Text>
      <Flex vertical align="center">
        <Row style={{ color: 'gray' }}>
          <LineChartOutlined />
          <Typography.Text style={{ marginLeft: 8 }}>
            {`OHAT評価回数 ${ohatScoresCount}回`}
          </Typography.Text>
        </Row>
        <Row style={{ color: 'gray' }}>
          <IdcardOutlined />
          <Typography.Text style={{ marginLeft: 8 }}>
            {`${
              lifeLevel === IndependentLifeLevel.none
                ? '日常生活自立度 なし'
                : lifeLevel.label
            } ${
              careLevel === PatientCareLevel.none
                ? '介護度 なし'
                : careLevel.label
            } `}
          </Typography.Text>
        </Row>
      </Flex>

      <Button
        shape="round"
        size="large"
        type="primary"
        onClick={onClick}
        icon={<MonitorOutlined />}
      >
        OHAT評価
      </Button>
    </Flex>
  );
};
