import { Form, Input, Button, Divider, Checkbox, Typography } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import useLoading from '../../../../hooks/useLoading';

export type Props = {
  onSubmit: (email: string, password: string) => void;
  buttonText: string;
  failedCallback?: (info: any) => void;
  showConfirm?: boolean;
};
export const SignInForm = (props: Props) => {
  const {
    onSubmit,
    buttonText,
    failedCallback = () => {},
    showConfirm = false,
  } = props;
  const [form] = Form.useForm();
  const { isLoading, startLoading, stopLoading } = useLoading();

  const onFinish = async (values: any) => {
    startLoading();
    await onSubmit(values.email, values.password);
    stopLoading();
  };

  const onFinishFailed = (errorInfo: any) => {
    failedCallback(errorInfo);
  };

  return (
    <Form
      name="loginForm"
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      style={{ width: '100%' }}
      layout="vertical" // フォームのレイアウトを垂直に設定
    >
      <Form.Item
        label="メールアドレス"
        name="email"
        rules={[
          { required: true, message: '必須入力です' },
          { type: 'email', message: '無効なメールアドレスです' },
        ]}
      >
        <Input size="large" prefix={<MailOutlined />} />
      </Form.Item>

      <Form.Item
        label="パスワード"
        name="password"
        rules={[
          { required: true, message: '必須入力です' },
          { min: 6, message: '６文字以上入力してください' },
        ]}
        hasFeedback
      >
        <Input.Password size="large" prefix={<LockOutlined />} />
      </Form.Item>
      {showConfirm && (
        <Form.Item
          label="パスワード確認"
          name="passwordConfirm"
          rules={[
            { required: true, message: '必須入力です' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('パスワードが一致しません'));
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password size="large" prefix={<LockOutlined />} />
        </Form.Item>
      )}
      {showConfirm && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center', // Flexアイテムを横軸（主軸ではない）中央に配置
            padding: 10,
            backgroundColor: '#F5FAFF',
            borderRadius: 5,
            textAlign: 'center', // テキストを中央寄せ
          }}
        >
          <Typography.Text style={{ fontSize: 14 }}>
            利用規約と個人情報の取り扱いにご同意の上、ご登録ください
          </Typography.Text>
          <Typography.Text
            style={{ fontSize: 10, paddingTop: 4, textAlign: 'start' }}
          >
            メディバンクス株式会社では個人情報の取り扱いに関して指針を定め、その保護に努めております。
            ご記入いただいた個人情報は、会員管理、アンケート、今後サービスのご提供をする目的で収集するものであり、本人の同意を得ずに他の目的に使用、第三者に提供・開示することは一切ありません。
          </Typography.Text>
          <Typography.Text style={{ fontSize: 10, textAlign: 'start' }}>
            また、個人情報については、個人情報保護方針に基づき、適切に取り扱います。
            弊社WEBサイトへ掲載の「個人情報の取り扱い」をご一読いただき、同意のうえ送信いただきますようお願いいたします。
          </Typography.Text>
          <div
            style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}
          >
            <a
              href="https://www.ohat.jp/%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84"
              target="_blank"
              rel="noreferrer"
            >
              <Typography.Text
                style={{
                  paddingTop: 4,
                  fontSize: 12,
                  textAlign: 'center',
                  textDecorationLine: 'underline',
                }}
              >
                利用規約
              </Typography.Text>
            </a>
            <a
              href="https://medi-banx.com/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              <Typography.Text
                style={{
                  paddingTop: 4,
                  fontSize: 12,
                  textAlign: 'center',
                  textDecorationLine: 'underline',
                }}
              >
                個人情報の取り扱い
              </Typography.Text>
            </a>
          </div>
          <div style={{ paddingTop: 4 }}>
            <Form.Item
              valuePropName="checked"
              name="agreement"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error('同意が必要です')),
                },
              ]}
            >
              <Checkbox>同意する</Checkbox>
            </Form.Item>
          </div>
        </div>
      )}
      <Divider />
      <Form.Item>
        <Button
          size="large"
          type="primary"
          block
          htmlType="submit"
          loading={isLoading}
        >
          {buttonText}
        </Button>
      </Form.Item>
    </Form>
  );
};
