import { Patient } from '@models/patient';
import { Avatar, List, Space, Tag, Typography } from 'antd';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirstChar } from '../../utils/string';
import { Gender } from '../../constants/gender';
import { PatientCareLevel } from '../../constants/careLevel';
import { IndependentLifeLevel } from '../../constants/independentLifeLevel';

export type Props = {
  patients: Patient[];
};
export const PatientList = (props: Props) => {
  const { patients } = props;
  const navigate = useNavigate();

  const patientData = useMemo(() => {
    return patients.map((v) => {
      return {
        key: v.id,
        id: v.id,
        name: v.name,
        age: v.age,
        gender: v.gender,
        careLevel: v.careLevel,
        lifeLevel: v.independentLifeLevel,
        // age: getAge(v.birthDate),
      };
    });
  }, [patients]);

  const onClick = (patientId: string) => {
    navigate(`/patients/${patientId}`);
  };

  return (
    <List
      itemLayout="vertical"
      size="large"
      pagination={{
        pageSize: 5,
      }}
      dataSource={patientData}
      renderItem={(item) => (
        <List.Item
          key={item.key}
          onClick={() => onClick(item.id)}
          className="hoverEffect"
        >
          <List.Item.Meta
            avatar={<Avatar>{getFirstChar(item.name)} </Avatar>}
            title={
              <Typography.Text style={{ fontSize: 18 }}>
                {item.name}
              </Typography.Text>
            }
            description={
              <Space size={[0, 8]} wrap>
                <Tag
                  color={item.gender === Gender.female.key ? 'red' : 'blue'}
                >{`${Gender.getByKey(item.gender).label}`}</Tag>
                {item.age && item.age !== 0 ? (
                  <Tag color="default">{`${item.age}歳`}</Tag>
                ) : null}
                {PatientCareLevel.getByKey(item.careLevel) !==
                  PatientCareLevel.none && (
                  <Tag color="volcano">{`${
                    PatientCareLevel.getByKey(item.careLevel).label
                  }`}</Tag>
                )}
                {IndependentLifeLevel.getByKey(item.lifeLevel) !==
                  IndependentLifeLevel.none && (
                  <Tag color="geekblue">{`${
                    IndependentLifeLevel.getByKey(item.lifeLevel).label
                  }`}</Tag>
                )}
              </Space>
            }
            style={{ marginBottom: 0 }}
          />
        </List.Item>
      )}
    />
  );
};
