import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { App, Button, Card, Flex } from 'antd';
import ResetPasswordForm from './form/ResetPasswordForm';

export function ResetPassword() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { message } = App.useApp();

  useEffect(() => {
    if (auth.isAuthenticated) navigate({ pathname: '/' });
  }, [auth, navigate]);

  const resetPassword = async (email: string) => {
    await auth.resetPasswordCognito(email);
    message.info('パスワード初期化メールを送付しました');
  };

  return (
    <Flex
      vertical
      style={{
        maxWidth: 500,
      }}
      gap={0}
    >
      <img
        src={`${process.env.PUBLIC_URL}/logo.svg`}
        alt="Logo"
        style={{ alignSelf: 'center', marginBottom: 25 }}
      />
      <Card title="パスワード初期化" style={{ minWidth: 320 }}>
        <ResetPasswordForm onSubmit={resetPassword} />
        <Button size="large" onClick={() => navigate({ pathname: '/' })} block>
          キャンセル
        </Button>
      </Card>
    </Flex>
  );
}
