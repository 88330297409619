import { Amplify } from 'aws-amplify';
import {
  signUp,
  signIn,
  getCurrentUser,
  resetPassword,
  confirmResetPassword,
  signOut,
  AuthUser,
} from '@aws-amplify/auth';
import AuthConfig from '../libs/auth';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { accountFindOne } from '../libs/ohat-api';
import { Account } from '../models';

Amplify.configure({ Auth: AuthConfig });

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  username: string;
  rawData?: AuthUser;
  account?: Account;
  signUpCognito: (username: string, password: string) => Promise<Result>;
  signInCognito: (username: string, password: string) => Promise<string>;
  signOutCognito: () => void;
  resetPasswordCognito: (username: string) => void;
  fetchAccount: (sub: string) => void;
  confirmResetPasswordCognito: (
    email: string,
    confirmationCode: string,
    newPassword: string,
  ) => void;
}

interface Result {
  success: boolean;
  message: string;
}

const authContext = createContext({} as UseAuth);

export interface ChildComponentProps {
  children: React.ReactNode;
}

export const ProvideAuth: React.FC<ChildComponentProps> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [rawData, setRawData] = useState<AuthUser>();
  const [account, setAccount] = useState<Account>();

  useEffect(() => {
    getCurrentUser()
      .then(async (result) => {
        setUsername(result.username);
        setRawData(result);
        await fetchAccount(result.username);
        setIsAuthenticated(true);
        setIsLoading(false);
      })
      .catch(() => {
        setUsername('');
        setIsAuthenticated(false);
        setIsLoading(false);
      });
  }, []);

  const signUpCognito = async (username: string, password: string) => {
    try {
      await signUp({ username, password });
      return { success: true, message: '' };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        message: '認証に失敗しました。',
      };
    }
  };

  const resetPasswordCognito = async (email: string) => {
    try {
      await resetPassword({ username: email });
    } catch (error) {
      console.error(error);
    }
  };

  const confirmResetPasswordCognito = async (
    email: string,
    confirmationCode: string,
    newPassword: string,
  ) => {
    await confirmResetPassword({
      username: email,
      confirmationCode,
      newPassword,
    });
  };

  const signInCognito = async (email: string, password: string) => {
    try {
      await signIn({ username: email, password });
      const user = await getCurrentUser();
      setUsername(user.username);
      setIsAuthenticated(true);
      await fetchAccount(user.username);
      return user.username;
    } catch (error) {
      console.error(error);
      return '';
    }
  };

  const fetchAccount = async (sub: string) => {
    const account = await accountFindOne(sub);
    if (account) setAccount(account);
  };

  const signOutCognito = async () => {
    try {
      await signOut();
      localStorage.clear();
      setUsername('');
      setIsAuthenticated(false);
      return { success: true, message: '' };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        message: 'ログアウトに失敗しました。',
      };
    }
  };

  return {
    isLoading,
    isAuthenticated,
    username,
    account,
    rawData,
    signUpCognito,
    signInCognito,
    signOutCognito,
    resetPasswordCognito,
    confirmResetPasswordCognito,
    fetchAccount,
  };
};
