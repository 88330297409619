export class BaseConstants {
  public readonly key: string = '';
  public readonly label: string = '';

  toString() {
    return this.label;
  }

  static getAll() {
    const val = Object.assign({}, this);
    return Object.entries(val).map(([_, v]) => v);
  }

  static getByKey(key: string) {
    return this.getAll().find((v) => v.key === key);
  }
}
