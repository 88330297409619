import { Organization } from '@models/organization';
import { Progress, Table } from 'antd';
import { useMemo } from 'react';
import { OrganizationType } from '../../../constants/orgType';
import Column from 'antd/es/table/Column';

export type Props = {
  organizations: Organization[];
};
export const OrganizationsTypeTable = (props: Props) => {
  const { organizations } = props;

  const organizationTypeData = useMemo(() => {
    // 所属施設種別を集計
    const counts = organizations.reduce((acc, org) => {
      const { type } = org;
      acc[type] = (acc[type] || 0) + 1;
      return acc;
    }, {} as { [key: string]: number });

    const total = Object.values(counts).reduce((sum, count) => sum + count, 0);

    const rates = Object.entries(counts).reduce((acc, [type, count]) => {
      acc[type] = Math.round((count / total) * 100);
      return acc;
    }, {} as { [key: string]: number });

    const aggregate = OrganizationType.getAll().map(
      (orgType: OrganizationType) => {
        return {
          key: orgType.key,
          typeName: orgType.label,
          typeCount: counts[orgType.key] ?? 0,
          typeRate: rates[orgType.key] ?? 0,
        };
      },
    );
    // const sortedAggregate = aggregate.sort((a, b) => b.typeCount - a.typeCount);
    return aggregate;
  }, [organizations]);

  return (
    <Table
      dataSource={organizationTypeData}
      size="small"
      bordered
      pagination={{
        pageSize: 50,
        hideOnSinglePage: true,
      }}
      className={'ant-aggregate-slim-table'}
    >
      <Column
        title="所属機関種別"
        dataIndex="typeName"
        key="typeName"
        width={'20%'}
        render={(val: string) => <p style={{ fontSize: 12 }}>{val}</p>}
      />
      <Column
        title="割合"
        dataIndex="typeRate"
        key="typeRate"
        render={(_val, record: any) => (
          <Progress
            percent={record['typeRate']}
            size={['90%', 15]}
            format={(p) => `${p}% (${record['typeCount']})`}
          />
        )}
      />
    </Table>
  );
};
