import { Form, Input, Button, Divider } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import useLoading from '../../../../hooks/useLoading';

export type Props = {
  onSubmit: (email: string, newPassword: string) => void;
  failedCallback?: (info: any) => void;
};
const VerifyResetPasswordForm = (props: Props) => {
  const { onSubmit, failedCallback = () => {} } = props;
  const { isLoading, startLoading, stopLoading } = useLoading();

  const onFinish = async (values: any) => {
    startLoading();
    await onSubmit(values.email, values.password);
    stopLoading();
  };

  const onFinishFailed = (errorInfo: any) => {
    failedCallback(errorInfo);
  };

  return (
    <Form
      name="resetPasswordForm"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      style={{ width: '100%' }}
      layout="vertical"
    >
      <Form.Item
        label="メールアドレス"
        name="email"
        rules={[
          { required: true, message: '必須入力です' },
          { type: 'email', message: '無効なメールアドレスです' },
        ]}
      >
        <Input size="large" prefix={<MailOutlined />} />
      </Form.Item>
      <Form.Item
        label="パスワード"
        name="password"
        rules={[
          { required: true, message: '必須入力です' },
          { min: 6, message: '６文字以上入力してください' },
        ]}
        hasFeedback
      >
        <Input.Password size="large" prefix={<LockOutlined />} />
      </Form.Item>
      <Form.Item
        label="パスワード確認"
        name="passwordConfirm"
        rules={[
          { required: true, message: '必須入力です' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('パスワードが一致しません'));
            },
          }),
        ]}
        hasFeedback
      >
        <Input.Password size="large" prefix={<LockOutlined />} />
      </Form.Item>
      <Divider />
      <Form.Item>
        <Button
          size="large"
          type="primary"
          block
          htmlType="submit"
          loading={isLoading}
        >
          設定
        </Button>
      </Form.Item>
    </Form>
  );
};

export default VerifyResetPasswordForm;
