import { Typography, Row, Divider } from 'antd';
import { Question, StepIndex } from '../../../constants/question';
import useMobile from '../../../hooks/useMobile';
import { Ref } from 'react';
import './OhatCheckQuestionRow.css';

const { Title } = Typography;

type Props = {
  onSelect: (step: StepIndex, answerIndex: number) => void;
  question: Question;
  answer: number;
  step: StepIndex;
  innerRef: Ref<HTMLDivElement>;
};
export default function OhatCheckQuestionRow(props: Props) {
  const { onSelect, question, answer, step, innerRef } = props;
  const { isMobile } = useMobile();

  return (
    <div ref={innerRef} style={{ cursor: 'pointer' }}>
      <Title level={isMobile() ? 3 : 2}>{`Q${step + 1}. ${
        question.label
      }`}</Title>
      <Divider />
      {Array.from({ length: 3 }).map((_, idx) => (
        <div key={idx}>
          <Row
            gutter={[0, 0]}
            key={idx}
            onClick={() => onSelect(step, idx)}
            className="question-row"
          >
            <div className="question-wrapper">
              <div
                className={
                  answer === idx ? 'question-label-selected' : 'question-label'
                }
              >
                <div>{question.choices[idx].status.label}</div>
                <div className="question-score-label">
                  {`スコア ${question.choices[idx].status.score}`}
                </div>
              </div>
              <div className={'question-description'}>
                {question.choices[idx].description.map((v, i) => (
                  <span key={i} className="question-text">{`${v}`}</span>
                ))}
              </div>
            </div>
            <div className="question-img-container">
              <img
                alt={'ohat'}
                src={question.choices[idx].imageSrc}
                className="question-img"
              />
            </div>
          </Row>
          <Divider />
        </div>
      ))}
    </div>
  );
}
