import { Breadcrumb } from 'antd';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export const PatientPageBreadCrumb = () => {
  const navigate = useNavigate();
  return (
    <Breadcrumb
      items={[
        {
          onClick: () => {
            navigate('/');
          },
          title: (
            <span style={{ cursor: 'pointer' }}>
              <HomeOutlined style={{ marginRight: 4 }} />
              <span>管理ページ</span>
            </span>
          ),
        },
        {
          title: (
            <>
              <UserOutlined />
              <span>患者ページ</span>
            </>
          ),
        },
      ]}
    />
  );
};
