import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import {
  useAccountCreate,
  useOrganizationFindAllPatients,
} from '../../libs/ohat-api';
import { App, Card, Col, Input, Row } from 'antd';
import { HomePageBreadCrumb } from '../components/breadcrumb/HomePageBreadCrumb';
import { OrganizationDetailPanel } from '../components/OrganizationDetailPanel';
import { PatientList } from '../components/PatientList';

export function HomePage() {
  const auth = useAuth();
  const { modal } = App.useApp();
  const navigate = useNavigate();
  const { mutateAsync: createAccount } = useAccountCreate();
  const [searchKey, setSearchKey] = useState('');
  // suspenseを使うと、ErrorBoundaryで処理されてしまうので、ここだけは使わない
  const { data: org } = useOrganizationFindAllPatients(
    auth.account?.organizationId ?? '',
  );
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(e.target.value);
  };

  const targetPatients = useMemo(() => {
    if (!org) return [];
    if (!org.patients) return [];
    return org.patients.filter((v) => v.name.includes(searchKey));
  }, [org, searchKey]);

  const showModal = () => {
    modal.info({
      content: 'ご使用にあたって、基本情報の入力をお願いします',
      onOk: () => {
        navigate({ pathname: '/organizations/create' });
      },
    });
  };

  const recoveryAccount = async (email: string, sub: string) => {
    try {
      const apiResult = await createAccount({
        data: {
          email,
          sub,
        },
      });
      if (apiResult) await auth.fetchAccount(sub);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    if (!auth.account) {
      // cognitoアカウントは存在するが、紐づくAccountレコードが存在しない場合はログアウト
      if (auth.rawData?.signInDetails?.loginId) {
        recoveryAccount(
          auth.rawData.signInDetails.loginId,
          auth.rawData.username,
        );
      }
    }
    if (auth.account && !auth.account.organization) {
      showModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <Row gutter={[16, 8]} style={{ flex: 1, maxWidth: 1200 }}>
      <Col md={24} xs={24}>
        <HomePageBreadCrumb />
      </Col>
      <Col md={8} xs={24}>
        <Card
          title="事業者情報"
          extra={org && <Link to={`organizations/${org.id}/edit`}>編集</Link>}
        >
          {org && <OrganizationDetailPanel organization={org} />}
        </Card>
      </Col>
      <Col md={16} xs={24}>
        <Card
          title="患者一覧"
          extra={<Input onChange={onChange} placeholder="氏名検索" />}
        >
          <PatientList patients={targetPatients} />
        </Card>
      </Col>
    </Row>
  );
}
