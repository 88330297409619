/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * ohat API
 * API群
 * OpenAPI spec version: 1.1
 */
import {
  useMutation,
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  Account,
  CreateAccountDto,
  CreateOhatScoreDto,
  CreateOrganizationDto,
  CreatePatientDto,
  HealthcheckResponseDto,
  HttpExceptionResponseDto,
  OhatScore,
  OhatScoreFindFirstAndLatestParams,
  Organization,
  Patient,
  PatientScoreCountDto,
  UpdateAccountDto,
  UpdateOrganizationDto,
  UpdatePatientDto
} from '../models'
import { customInstance } from './axios-config';
import type { ErrorType, BodyType } from './axios-config';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * @summary 新規Cognitoアカウントおよび初期データの作成
 */
export const accountCreate = (
    createAccountDto: BodyType<CreateAccountDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<Account>(
      {url: `/accounts`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createAccountDto
    },
      options);
    }
  


export const getAccountCreateMutationOptions = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountCreate>>, TError,{data: BodyType<CreateAccountDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof accountCreate>>, TError,{data: BodyType<CreateAccountDto>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof accountCreate>>, {data: BodyType<CreateAccountDto>}> = (props) => {
          const {data} = props ?? {};

          return  accountCreate(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AccountCreateMutationResult = NonNullable<Awaited<ReturnType<typeof accountCreate>>>
    export type AccountCreateMutationBody = BodyType<CreateAccountDto>
    export type AccountCreateMutationError = ErrorType<HttpExceptionResponseDto>

    /**
 * @summary 新規Cognitoアカウントおよび初期データの作成
 */
export const useAccountCreate = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountCreate>>, TError,{data: BodyType<CreateAccountDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {

      const mutationOptions = getAccountCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary accountsの取得
 */
export const accountFindAll = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Account[]>(
      {url: `/accounts`, method: 'GET', signal
    },
      options);
    }
  

export const getAccountFindAllQueryKey = () => {
    return [`/accounts`] as const;
    }

    
export const getAccountFindAllQueryOptions = <TData = Awaited<ReturnType<typeof accountFindAll>>, TError = ErrorType<HttpExceptionResponseDto>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof accountFindAll>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAccountFindAllQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof accountFindAll>>> = ({ signal }) => accountFindAll(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof accountFindAll>>, TError, TData> & { queryKey: QueryKey }
}

export type AccountFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof accountFindAll>>>
export type AccountFindAllQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary accountsの取得
 */
export const useAccountFindAll = <TData = Awaited<ReturnType<typeof accountFindAll>>, TError = ErrorType<HttpExceptionResponseDto>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof accountFindAll>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAccountFindAllQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAccountFindAllSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof accountFindAll>>, TError = ErrorType<HttpExceptionResponseDto>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof accountFindAll>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAccountFindAllQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof accountFindAll>>> = ({ signal }) => accountFindAll(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof accountFindAll>>, TError, TData> & { queryKey: QueryKey }
}

export type AccountFindAllSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof accountFindAll>>>
export type AccountFindAllSuspenseQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary accountsの取得
 */
export const useAccountFindAllSuspense = <TData = Awaited<ReturnType<typeof accountFindAll>>, TError = ErrorType<HttpExceptionResponseDto>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof accountFindAll>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAccountFindAllSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary accountの取得
 */
export const accountFindOne = (
    sub: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Account>(
      {url: `/accounts/${sub}`, method: 'GET', signal
    },
      options);
    }
  

export const getAccountFindOneQueryKey = (sub: string,) => {
    return [`/accounts/${sub}`] as const;
    }

    
export const getAccountFindOneQueryOptions = <TData = Awaited<ReturnType<typeof accountFindOne>>, TError = ErrorType<HttpExceptionResponseDto>>(sub: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof accountFindOne>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAccountFindOneQueryKey(sub);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof accountFindOne>>> = ({ signal }) => accountFindOne(sub, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(sub), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof accountFindOne>>, TError, TData> & { queryKey: QueryKey }
}

export type AccountFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof accountFindOne>>>
export type AccountFindOneQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary accountの取得
 */
export const useAccountFindOne = <TData = Awaited<ReturnType<typeof accountFindOne>>, TError = ErrorType<HttpExceptionResponseDto>>(
 sub: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof accountFindOne>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAccountFindOneQueryOptions(sub,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAccountFindOneSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof accountFindOne>>, TError = ErrorType<HttpExceptionResponseDto>>(sub: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof accountFindOne>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAccountFindOneQueryKey(sub);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof accountFindOne>>> = ({ signal }) => accountFindOne(sub, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(sub), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof accountFindOne>>, TError, TData> & { queryKey: QueryKey }
}

export type AccountFindOneSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof accountFindOne>>>
export type AccountFindOneSuspenseQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary accountの取得
 */
export const useAccountFindOneSuspense = <TData = Awaited<ReturnType<typeof accountFindOne>>, TError = ErrorType<HttpExceptionResponseDto>>(
 sub: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof accountFindOne>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAccountFindOneSuspenseQueryOptions(sub,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary accountの更新
 */
export const accountUpdate = (
    id: string,
    updateAccountDto: BodyType<UpdateAccountDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<Account>(
      {url: `/accounts/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateAccountDto
    },
      options);
    }
  


export const getAccountUpdateMutationOptions = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountUpdate>>, TError,{id: string;data: BodyType<UpdateAccountDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof accountUpdate>>, TError,{id: string;data: BodyType<UpdateAccountDto>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof accountUpdate>>, {id: string;data: BodyType<UpdateAccountDto>}> = (props) => {
          const {id,data} = props ?? {};

          return  accountUpdate(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AccountUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof accountUpdate>>>
    export type AccountUpdateMutationBody = BodyType<UpdateAccountDto>
    export type AccountUpdateMutationError = ErrorType<HttpExceptionResponseDto>

    /**
 * @summary accountの更新
 */
export const useAccountUpdate = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountUpdate>>, TError,{id: string;data: BodyType<UpdateAccountDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {

      const mutationOptions = getAccountUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary accountの最終アクティビティ時刻更新
 */
export const accountUpdateActivity = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<Account>(
      {url: `/accounts/${id}/activity`, method: 'PATCH'
    },
      options);
    }
  


export const getAccountUpdateActivityMutationOptions = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountUpdateActivity>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof accountUpdateActivity>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof accountUpdateActivity>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  accountUpdateActivity(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AccountUpdateActivityMutationResult = NonNullable<Awaited<ReturnType<typeof accountUpdateActivity>>>
    
    export type AccountUpdateActivityMutationError = ErrorType<HttpExceptionResponseDto>

    /**
 * @summary accountの最終アクティビティ時刻更新
 */
export const useAccountUpdateActivity = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountUpdateActivity>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {

      const mutationOptions = getAccountUpdateActivityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary ヘルスチェック
 */
export const healthcheckGetPayment = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<HealthcheckResponseDto>(
      {url: `/`, method: 'GET', signal
    },
      options);
    }
  

export const getHealthcheckGetPaymentQueryKey = () => {
    return [`/`] as const;
    }

    
export const getHealthcheckGetPaymentQueryOptions = <TData = Awaited<ReturnType<typeof healthcheckGetPayment>>, TError = ErrorType<HttpExceptionResponseDto>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof healthcheckGetPayment>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getHealthcheckGetPaymentQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof healthcheckGetPayment>>> = ({ signal }) => healthcheckGetPayment(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof healthcheckGetPayment>>, TError, TData> & { queryKey: QueryKey }
}

export type HealthcheckGetPaymentQueryResult = NonNullable<Awaited<ReturnType<typeof healthcheckGetPayment>>>
export type HealthcheckGetPaymentQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary ヘルスチェック
 */
export const useHealthcheckGetPayment = <TData = Awaited<ReturnType<typeof healthcheckGetPayment>>, TError = ErrorType<HttpExceptionResponseDto>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof healthcheckGetPayment>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getHealthcheckGetPaymentQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getHealthcheckGetPaymentSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof healthcheckGetPayment>>, TError = ErrorType<HttpExceptionResponseDto>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof healthcheckGetPayment>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getHealthcheckGetPaymentQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof healthcheckGetPayment>>> = ({ signal }) => healthcheckGetPayment(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof healthcheckGetPayment>>, TError, TData> & { queryKey: QueryKey }
}

export type HealthcheckGetPaymentSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof healthcheckGetPayment>>>
export type HealthcheckGetPaymentSuspenseQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary ヘルスチェック
 */
export const useHealthcheckGetPaymentSuspense = <TData = Awaited<ReturnType<typeof healthcheckGetPayment>>, TError = ErrorType<HttpExceptionResponseDto>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof healthcheckGetPayment>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getHealthcheckGetPaymentSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary organizationの作成
 */
export const organizationCreate = (
    createOrganizationDto: BodyType<CreateOrganizationDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<Organization>(
      {url: `/organizations`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createOrganizationDto
    },
      options);
    }
  


export const getOrganizationCreateMutationOptions = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationCreate>>, TError,{data: BodyType<CreateOrganizationDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof organizationCreate>>, TError,{data: BodyType<CreateOrganizationDto>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof organizationCreate>>, {data: BodyType<CreateOrganizationDto>}> = (props) => {
          const {data} = props ?? {};

          return  organizationCreate(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type OrganizationCreateMutationResult = NonNullable<Awaited<ReturnType<typeof organizationCreate>>>
    export type OrganizationCreateMutationBody = BodyType<CreateOrganizationDto>
    export type OrganizationCreateMutationError = ErrorType<HttpExceptionResponseDto>

    /**
 * @summary organizationの作成
 */
export const useOrganizationCreate = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationCreate>>, TError,{data: BodyType<CreateOrganizationDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {

      const mutationOptions = getOrganizationCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary organizationsの取得
 */
export const organizationFindAll = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Organization[]>(
      {url: `/organizations`, method: 'GET', signal
    },
      options);
    }
  

export const getOrganizationFindAllQueryKey = () => {
    return [`/organizations`] as const;
    }

    
export const getOrganizationFindAllQueryOptions = <TData = Awaited<ReturnType<typeof organizationFindAll>>, TError = ErrorType<HttpExceptionResponseDto>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationFindAll>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationFindAllQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationFindAll>>> = ({ signal }) => organizationFindAll(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof organizationFindAll>>, TError, TData> & { queryKey: QueryKey }
}

export type OrganizationFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof organizationFindAll>>>
export type OrganizationFindAllQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary organizationsの取得
 */
export const useOrganizationFindAll = <TData = Awaited<ReturnType<typeof organizationFindAll>>, TError = ErrorType<HttpExceptionResponseDto>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationFindAll>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationFindAllQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getOrganizationFindAllSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof organizationFindAll>>, TError = ErrorType<HttpExceptionResponseDto>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationFindAll>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationFindAllQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationFindAll>>> = ({ signal }) => organizationFindAll(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationFindAll>>, TError, TData> & { queryKey: QueryKey }
}

export type OrganizationFindAllSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof organizationFindAll>>>
export type OrganizationFindAllSuspenseQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary organizationsの取得
 */
export const useOrganizationFindAllSuspense = <TData = Awaited<ReturnType<typeof organizationFindAll>>, TError = ErrorType<HttpExceptionResponseDto>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationFindAll>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationFindAllSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary organizationの取得
 */
export const organizationFindOne = (
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Organization>(
      {url: `/organizations/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getOrganizationFindOneQueryKey = (id: string,) => {
    return [`/organizations/${id}`] as const;
    }

    
export const getOrganizationFindOneQueryOptions = <TData = Awaited<ReturnType<typeof organizationFindOne>>, TError = ErrorType<HttpExceptionResponseDto>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationFindOne>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationFindOneQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationFindOne>>> = ({ signal }) => organizationFindOne(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof organizationFindOne>>, TError, TData> & { queryKey: QueryKey }
}

export type OrganizationFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof organizationFindOne>>>
export type OrganizationFindOneQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary organizationの取得
 */
export const useOrganizationFindOne = <TData = Awaited<ReturnType<typeof organizationFindOne>>, TError = ErrorType<HttpExceptionResponseDto>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationFindOne>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationFindOneQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getOrganizationFindOneSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof organizationFindOne>>, TError = ErrorType<HttpExceptionResponseDto>>(id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationFindOne>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationFindOneQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationFindOne>>> = ({ signal }) => organizationFindOne(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationFindOne>>, TError, TData> & { queryKey: QueryKey }
}

export type OrganizationFindOneSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof organizationFindOne>>>
export type OrganizationFindOneSuspenseQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary organizationの取得
 */
export const useOrganizationFindOneSuspense = <TData = Awaited<ReturnType<typeof organizationFindOne>>, TError = ErrorType<HttpExceptionResponseDto>>(
 id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationFindOne>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationFindOneSuspenseQueryOptions(id,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary organizationの更新
 */
export const organizationUpdate = (
    id: string,
    updateOrganizationDto: BodyType<UpdateOrganizationDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<Organization>(
      {url: `/organizations/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateOrganizationDto
    },
      options);
    }
  


export const getOrganizationUpdateMutationOptions = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationUpdate>>, TError,{id: string;data: BodyType<UpdateOrganizationDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof organizationUpdate>>, TError,{id: string;data: BodyType<UpdateOrganizationDto>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof organizationUpdate>>, {id: string;data: BodyType<UpdateOrganizationDto>}> = (props) => {
          const {id,data} = props ?? {};

          return  organizationUpdate(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type OrganizationUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof organizationUpdate>>>
    export type OrganizationUpdateMutationBody = BodyType<UpdateOrganizationDto>
    export type OrganizationUpdateMutationError = ErrorType<HttpExceptionResponseDto>

    /**
 * @summary organizationの更新
 */
export const useOrganizationUpdate = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationUpdate>>, TError,{id: string;data: BodyType<UpdateOrganizationDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {

      const mutationOptions = getOrganizationUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary organizationの削除
 */
export const organizationRemove = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<Organization>(
      {url: `/organizations/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getOrganizationRemoveMutationOptions = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationRemove>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof organizationRemove>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof organizationRemove>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  organizationRemove(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type OrganizationRemoveMutationResult = NonNullable<Awaited<ReturnType<typeof organizationRemove>>>
    
    export type OrganizationRemoveMutationError = ErrorType<HttpExceptionResponseDto>

    /**
 * @summary organizationの削除
 */
export const useOrganizationRemove = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationRemove>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {

      const mutationOptions = getOrganizationRemoveMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary patientsの取得
 */
export const organizationFindAllPatients = (
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Organization>(
      {url: `/organizations/${id}/patients`, method: 'GET', signal
    },
      options);
    }
  

export const getOrganizationFindAllPatientsQueryKey = (id: string,) => {
    return [`/organizations/${id}/patients`] as const;
    }

    
export const getOrganizationFindAllPatientsQueryOptions = <TData = Awaited<ReturnType<typeof organizationFindAllPatients>>, TError = ErrorType<HttpExceptionResponseDto>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationFindAllPatients>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationFindAllPatientsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationFindAllPatients>>> = ({ signal }) => organizationFindAllPatients(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof organizationFindAllPatients>>, TError, TData> & { queryKey: QueryKey }
}

export type OrganizationFindAllPatientsQueryResult = NonNullable<Awaited<ReturnType<typeof organizationFindAllPatients>>>
export type OrganizationFindAllPatientsQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary patientsの取得
 */
export const useOrganizationFindAllPatients = <TData = Awaited<ReturnType<typeof organizationFindAllPatients>>, TError = ErrorType<HttpExceptionResponseDto>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationFindAllPatients>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationFindAllPatientsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getOrganizationFindAllPatientsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof organizationFindAllPatients>>, TError = ErrorType<HttpExceptionResponseDto>>(id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationFindAllPatients>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationFindAllPatientsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationFindAllPatients>>> = ({ signal }) => organizationFindAllPatients(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationFindAllPatients>>, TError, TData> & { queryKey: QueryKey }
}

export type OrganizationFindAllPatientsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof organizationFindAllPatients>>>
export type OrganizationFindAllPatientsSuspenseQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary patientsの取得
 */
export const useOrganizationFindAllPatientsSuspense = <TData = Awaited<ReturnType<typeof organizationFindAllPatients>>, TError = ErrorType<HttpExceptionResponseDto>>(
 id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationFindAllPatients>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationFindAllPatientsSuspenseQueryOptions(id,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary accountsの取得
 */
export const organizationFindAllAccounts = (
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Organization>(
      {url: `/organizations/${id}/accounts`, method: 'GET', signal
    },
      options);
    }
  

export const getOrganizationFindAllAccountsQueryKey = (id: string,) => {
    return [`/organizations/${id}/accounts`] as const;
    }

    
export const getOrganizationFindAllAccountsQueryOptions = <TData = Awaited<ReturnType<typeof organizationFindAllAccounts>>, TError = ErrorType<HttpExceptionResponseDto>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationFindAllAccounts>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationFindAllAccountsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationFindAllAccounts>>> = ({ signal }) => organizationFindAllAccounts(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof organizationFindAllAccounts>>, TError, TData> & { queryKey: QueryKey }
}

export type OrganizationFindAllAccountsQueryResult = NonNullable<Awaited<ReturnType<typeof organizationFindAllAccounts>>>
export type OrganizationFindAllAccountsQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary accountsの取得
 */
export const useOrganizationFindAllAccounts = <TData = Awaited<ReturnType<typeof organizationFindAllAccounts>>, TError = ErrorType<HttpExceptionResponseDto>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationFindAllAccounts>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationFindAllAccountsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getOrganizationFindAllAccountsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof organizationFindAllAccounts>>, TError = ErrorType<HttpExceptionResponseDto>>(id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationFindAllAccounts>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationFindAllAccountsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationFindAllAccounts>>> = ({ signal }) => organizationFindAllAccounts(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationFindAllAccounts>>, TError, TData> & { queryKey: QueryKey }
}

export type OrganizationFindAllAccountsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof organizationFindAllAccounts>>>
export type OrganizationFindAllAccountsSuspenseQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary accountsの取得
 */
export const useOrganizationFindAllAccountsSuspense = <TData = Awaited<ReturnType<typeof organizationFindAllAccounts>>, TError = ErrorType<HttpExceptionResponseDto>>(
 id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationFindAllAccounts>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationFindAllAccountsSuspenseQueryOptions(id,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary patientの作成
 */
export const patientCreate = (
    createPatientDto: BodyType<CreatePatientDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<Patient>(
      {url: `/patients`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createPatientDto
    },
      options);
    }
  


export const getPatientCreateMutationOptions = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientCreate>>, TError,{data: BodyType<CreatePatientDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patientCreate>>, TError,{data: BodyType<CreatePatientDto>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patientCreate>>, {data: BodyType<CreatePatientDto>}> = (props) => {
          const {data} = props ?? {};

          return  patientCreate(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PatientCreateMutationResult = NonNullable<Awaited<ReturnType<typeof patientCreate>>>
    export type PatientCreateMutationBody = BodyType<CreatePatientDto>
    export type PatientCreateMutationError = ErrorType<HttpExceptionResponseDto>

    /**
 * @summary patientの作成
 */
export const usePatientCreate = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientCreate>>, TError,{data: BodyType<CreatePatientDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {

      const mutationOptions = getPatientCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary patientの取得
 */
export const patientFindOne = (
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Patient>(
      {url: `/patients/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getPatientFindOneQueryKey = (id: string,) => {
    return [`/patients/${id}`] as const;
    }

    
export const getPatientFindOneQueryOptions = <TData = Awaited<ReturnType<typeof patientFindOne>>, TError = ErrorType<HttpExceptionResponseDto>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof patientFindOne>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPatientFindOneQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof patientFindOne>>> = ({ signal }) => patientFindOne(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof patientFindOne>>, TError, TData> & { queryKey: QueryKey }
}

export type PatientFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof patientFindOne>>>
export type PatientFindOneQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary patientの取得
 */
export const usePatientFindOne = <TData = Awaited<ReturnType<typeof patientFindOne>>, TError = ErrorType<HttpExceptionResponseDto>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof patientFindOne>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPatientFindOneQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPatientFindOneSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof patientFindOne>>, TError = ErrorType<HttpExceptionResponseDto>>(id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof patientFindOne>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPatientFindOneQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof patientFindOne>>> = ({ signal }) => patientFindOne(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof patientFindOne>>, TError, TData> & { queryKey: QueryKey }
}

export type PatientFindOneSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof patientFindOne>>>
export type PatientFindOneSuspenseQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary patientの取得
 */
export const usePatientFindOneSuspense = <TData = Awaited<ReturnType<typeof patientFindOne>>, TError = ErrorType<HttpExceptionResponseDto>>(
 id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof patientFindOne>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPatientFindOneSuspenseQueryOptions(id,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary patientの更新
 */
export const patientUpdate = (
    id: string,
    updatePatientDto: BodyType<UpdatePatientDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<Patient>(
      {url: `/patients/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updatePatientDto
    },
      options);
    }
  


export const getPatientUpdateMutationOptions = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientUpdate>>, TError,{id: string;data: BodyType<UpdatePatientDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patientUpdate>>, TError,{id: string;data: BodyType<UpdatePatientDto>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patientUpdate>>, {id: string;data: BodyType<UpdatePatientDto>}> = (props) => {
          const {id,data} = props ?? {};

          return  patientUpdate(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PatientUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof patientUpdate>>>
    export type PatientUpdateMutationBody = BodyType<UpdatePatientDto>
    export type PatientUpdateMutationError = ErrorType<HttpExceptionResponseDto>

    /**
 * @summary patientの更新
 */
export const usePatientUpdate = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientUpdate>>, TError,{id: string;data: BodyType<UpdatePatientDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {

      const mutationOptions = getPatientUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary patientの削除
 */
export const patientRemove = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<Patient>(
      {url: `/patients/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getPatientRemoveMutationOptions = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientRemove>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patientRemove>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patientRemove>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  patientRemove(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PatientRemoveMutationResult = NonNullable<Awaited<ReturnType<typeof patientRemove>>>
    
    export type PatientRemoveMutationError = ErrorType<HttpExceptionResponseDto>

    /**
 * @summary patientの削除
 */
export const usePatientRemove = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientRemove>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {

      const mutationOptions = getPatientRemoveMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary patientとohat-scoresの取得
 */
export const patientFindOhatScores = (
    id: string,
    yyyy: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PatientScoreCountDto>(
      {url: `/patients/${id}/ohat-scores/${yyyy}`, method: 'GET', signal
    },
      options);
    }
  

export const getPatientFindOhatScoresQueryKey = (id: string,
    yyyy: string,) => {
    return [`/patients/${id}/ohat-scores/${yyyy}`] as const;
    }

    
export const getPatientFindOhatScoresQueryOptions = <TData = Awaited<ReturnType<typeof patientFindOhatScores>>, TError = ErrorType<HttpExceptionResponseDto>>(id: string,
    yyyy: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof patientFindOhatScores>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPatientFindOhatScoresQueryKey(id,yyyy);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof patientFindOhatScores>>> = ({ signal }) => patientFindOhatScores(id,yyyy, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id && yyyy), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof patientFindOhatScores>>, TError, TData> & { queryKey: QueryKey }
}

export type PatientFindOhatScoresQueryResult = NonNullable<Awaited<ReturnType<typeof patientFindOhatScores>>>
export type PatientFindOhatScoresQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary patientとohat-scoresの取得
 */
export const usePatientFindOhatScores = <TData = Awaited<ReturnType<typeof patientFindOhatScores>>, TError = ErrorType<HttpExceptionResponseDto>>(
 id: string,
    yyyy: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof patientFindOhatScores>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPatientFindOhatScoresQueryOptions(id,yyyy,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPatientFindOhatScoresSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof patientFindOhatScores>>, TError = ErrorType<HttpExceptionResponseDto>>(id: string,
    yyyy: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof patientFindOhatScores>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPatientFindOhatScoresQueryKey(id,yyyy);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof patientFindOhatScores>>> = ({ signal }) => patientFindOhatScores(id,yyyy, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id && yyyy), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof patientFindOhatScores>>, TError, TData> & { queryKey: QueryKey }
}

export type PatientFindOhatScoresSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof patientFindOhatScores>>>
export type PatientFindOhatScoresSuspenseQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary patientとohat-scoresの取得
 */
export const usePatientFindOhatScoresSuspense = <TData = Awaited<ReturnType<typeof patientFindOhatScores>>, TError = ErrorType<HttpExceptionResponseDto>>(
 id: string,
    yyyy: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof patientFindOhatScores>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPatientFindOhatScoresSuspenseQueryOptions(id,yyyy,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary ohatScoreの作成
 */
export const ohatScoreCreate = (
    createOhatScoreDto: BodyType<CreateOhatScoreDto>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<OhatScore>(
      {url: `/ohat-scores`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createOhatScoreDto
    },
      options);
    }
  


export const getOhatScoreCreateMutationOptions = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof ohatScoreCreate>>, TError,{data: BodyType<CreateOhatScoreDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof ohatScoreCreate>>, TError,{data: BodyType<CreateOhatScoreDto>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof ohatScoreCreate>>, {data: BodyType<CreateOhatScoreDto>}> = (props) => {
          const {data} = props ?? {};

          return  ohatScoreCreate(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type OhatScoreCreateMutationResult = NonNullable<Awaited<ReturnType<typeof ohatScoreCreate>>>
    export type OhatScoreCreateMutationBody = BodyType<CreateOhatScoreDto>
    export type OhatScoreCreateMutationError = ErrorType<HttpExceptionResponseDto>

    /**
 * @summary ohatScoreの作成
 */
export const useOhatScoreCreate = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof ohatScoreCreate>>, TError,{data: BodyType<CreateOhatScoreDto>}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {

      const mutationOptions = getOhatScoreCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary ohatScoreの取得
 */
export const ohatScoreFindOne = (
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<OhatScore>(
      {url: `/ohat-scores/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getOhatScoreFindOneQueryKey = (id: string,) => {
    return [`/ohat-scores/${id}`] as const;
    }

    
export const getOhatScoreFindOneQueryOptions = <TData = Awaited<ReturnType<typeof ohatScoreFindOne>>, TError = ErrorType<HttpExceptionResponseDto>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ohatScoreFindOne>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOhatScoreFindOneQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof ohatScoreFindOne>>> = ({ signal }) => ohatScoreFindOne(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof ohatScoreFindOne>>, TError, TData> & { queryKey: QueryKey }
}

export type OhatScoreFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof ohatScoreFindOne>>>
export type OhatScoreFindOneQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary ohatScoreの取得
 */
export const useOhatScoreFindOne = <TData = Awaited<ReturnType<typeof ohatScoreFindOne>>, TError = ErrorType<HttpExceptionResponseDto>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ohatScoreFindOne>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOhatScoreFindOneQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getOhatScoreFindOneSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof ohatScoreFindOne>>, TError = ErrorType<HttpExceptionResponseDto>>(id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof ohatScoreFindOne>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOhatScoreFindOneQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof ohatScoreFindOne>>> = ({ signal }) => ohatScoreFindOne(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof ohatScoreFindOne>>, TError, TData> & { queryKey: QueryKey }
}

export type OhatScoreFindOneSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof ohatScoreFindOne>>>
export type OhatScoreFindOneSuspenseQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary ohatScoreの取得
 */
export const useOhatScoreFindOneSuspense = <TData = Awaited<ReturnType<typeof ohatScoreFindOne>>, TError = ErrorType<HttpExceptionResponseDto>>(
 id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof ohatScoreFindOne>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOhatScoreFindOneSuspenseQueryOptions(id,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary ohatScoreの削除
 */
export const ohatScoreRemove = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<OhatScore>(
      {url: `/ohat-scores/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getOhatScoreRemoveMutationOptions = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof ohatScoreRemove>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof ohatScoreRemove>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof ohatScoreRemove>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  ohatScoreRemove(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type OhatScoreRemoveMutationResult = NonNullable<Awaited<ReturnType<typeof ohatScoreRemove>>>
    
    export type OhatScoreRemoveMutationError = ErrorType<HttpExceptionResponseDto>

    /**
 * @summary ohatScoreの削除
 */
export const useOhatScoreRemove = <TError = ErrorType<HttpExceptionResponseDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof ohatScoreRemove>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {

      const mutationOptions = getOhatScoreRemoveMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary 初回のohatScoreの取得
 */
export const ohatScoreFindFirstAndLatest = (
    patientId: string,
    params: OhatScoreFindFirstAndLatestParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<OhatScore[]>(
      {url: `/ohat-scores/${patientId}/first-and-latest`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getOhatScoreFindFirstAndLatestQueryKey = (patientId: string,
    params: OhatScoreFindFirstAndLatestParams,) => {
    return [`/ohat-scores/${patientId}/first-and-latest`, ...(params ? [params]: [])] as const;
    }

    
export const getOhatScoreFindFirstAndLatestQueryOptions = <TData = Awaited<ReturnType<typeof ohatScoreFindFirstAndLatest>>, TError = ErrorType<HttpExceptionResponseDto>>(patientId: string,
    params: OhatScoreFindFirstAndLatestParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ohatScoreFindFirstAndLatest>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOhatScoreFindFirstAndLatestQueryKey(patientId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof ohatScoreFindFirstAndLatest>>> = ({ signal }) => ohatScoreFindFirstAndLatest(patientId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(patientId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof ohatScoreFindFirstAndLatest>>, TError, TData> & { queryKey: QueryKey }
}

export type OhatScoreFindFirstAndLatestQueryResult = NonNullable<Awaited<ReturnType<typeof ohatScoreFindFirstAndLatest>>>
export type OhatScoreFindFirstAndLatestQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary 初回のohatScoreの取得
 */
export const useOhatScoreFindFirstAndLatest = <TData = Awaited<ReturnType<typeof ohatScoreFindFirstAndLatest>>, TError = ErrorType<HttpExceptionResponseDto>>(
 patientId: string,
    params: OhatScoreFindFirstAndLatestParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ohatScoreFindFirstAndLatest>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOhatScoreFindFirstAndLatestQueryOptions(patientId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getOhatScoreFindFirstAndLatestSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof ohatScoreFindFirstAndLatest>>, TError = ErrorType<HttpExceptionResponseDto>>(patientId: string,
    params: OhatScoreFindFirstAndLatestParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof ohatScoreFindFirstAndLatest>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOhatScoreFindFirstAndLatestQueryKey(patientId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof ohatScoreFindFirstAndLatest>>> = ({ signal }) => ohatScoreFindFirstAndLatest(patientId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(patientId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof ohatScoreFindFirstAndLatest>>, TError, TData> & { queryKey: QueryKey }
}

export type OhatScoreFindFirstAndLatestSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof ohatScoreFindFirstAndLatest>>>
export type OhatScoreFindFirstAndLatestSuspenseQueryError = ErrorType<HttpExceptionResponseDto>

/**
 * @summary 初回のohatScoreの取得
 */
export const useOhatScoreFindFirstAndLatestSuspense = <TData = Awaited<ReturnType<typeof ohatScoreFindFirstAndLatest>>, TError = ErrorType<HttpExceptionResponseDto>>(
 patientId: string,
    params: OhatScoreFindFirstAndLatestParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof ohatScoreFindFirstAndLatest>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOhatScoreFindFirstAndLatestSuspenseQueryOptions(patientId,params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




