import { BaseConstants } from './baseConstants';

export class IndependentLifeLevel extends BaseConstants {
  static readonly none = new IndependentLifeLevel('none', 'なし');
  static readonly rankJ = new IndependentLifeLevel('j', '生活自立 ランクJ');
  static readonly rankA = new IndependentLifeLevel('a', '準寝たきり ランクA');
  static readonly rankB = new IndependentLifeLevel('b', '寝たきり ランクB');
  static readonly rankC = new IndependentLifeLevel('c', '寝たきり ランクC');

  private constructor(
    public readonly key: string,
    public readonly label: string,
  ) {
    super();
  }

  toString() {
    return this.key;
  }
}
