import { useMemo, useState } from 'react';
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { StepIndex } from '../../../constants/question';
import useMobile from '../../../hooks/useMobile';
import { Checkbox, Row, Space } from 'antd';

export type Answers = {
  score: {
    0: number;
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
    6: number;
    7: number;
  };
  evaluatedAt: string;
};

type Props = {
  answers: Answers;
  oldAnswers: Answers[];
};
const baseData = [
  {
    subject: 'Q1.口唇',
    thisScore: 0,
    firstScore: 0,
    latestScore: 0,
    fullMark: 2,
  },
  {
    subject: 'Q2.舌',
    thisScore: 0,
    firstScore: 0,
    latestScore: 0,
    fullMark: 2,
  },
  {
    subject: 'Q3.歯肉.粘膜',
    thisScore: 0,
    firstScore: 0,
    latestScore: 0,
    fullMark: 2,
  },
  {
    subject: 'Q4.唾液',
    thisScore: 0,
    firstScore: 0,
    latestScore: 0,
    fullMark: 2,
  },
  {
    subject: 'Q5.残存歯',
    thisScore: 0,
    firstScore: 0,
    latestScore: 0,
    fullMark: 2,
  },
  {
    subject: 'Q6.義歯',
    thisScore: 0,
    firstScore: 0,
    latestScore: 0,
    fullMark: 2,
  },
  {
    subject: 'Q7.口腔.清掃',
    thisScore: 0,
    firstScore: 0,
    latestScore: 0,
    fullMark: 2,
  },
  {
    subject: 'Q8.歯痛',
    thisScore: 0,
    firstScore: 0,
    latestScore: 0,
    fullMark: 2,
  },
];
export default function OhatResultGraph(props: Props) {
  const { answers, oldAnswers } = props;
  const { colSize } = useMobile();
  const [showFirstResult, setShowFirstResult] = useState(true);
  const [showLatestResult, setShowLatestResult] = useState(true);

  const allData = useMemo(() => {
    const newData = [...baseData];
    const [first, latest] = oldAnswers;
    newData.forEach((v, i) => {
      const answerIdx = i as StepIndex;
      // スコアが 0 の場合、0.3 に置き換える
      v['thisScore'] =
        answers.score[answerIdx] === 0 ? 0.3 : answers.score[answerIdx];
      if (first)
        v['firstScore'] =
          first.score[answerIdx] === 0 ? 0.3 : first.score[answerIdx];
      if (latest)
        v['latestScore'] =
          latest.score[answerIdx] === 0 ? 0.3 : latest.score[answerIdx];
    });
    return newData;
  }, [answers, oldAnswers]);

  function customTick(tickProps: any) {
    const { payload, x, y, textAnchor, stroke } = tickProps;
    const textParts = payload.value.split('.');
    return (
      <g className="recharts-layer recharts-polar-angle-axis-tick">
        <text
          stroke={stroke}
          x={x}
          y={y}
          className="recharts-text recharts-polar-angle-axis-tick-value"
          textAnchor={textAnchor}
        >
          {textParts.map((part: any, index: any) => (
            <tspan
              x={x}
              dy={index !== 0 ? `1.2em` : part === 'Q1' ? '-12px' : 3} //  Q1だけ、グラフに文字がかかってしまうための対応
              key={`tspan-${index}`}
            >
              {part}
            </tspan>
          ))}
        </text>
      </g>
    );
  }

  if (!allData) return null;

  return (
    <Space.Compact
      direction="vertical"
      style={{ width: '100%', paddingTop: 20 }}
    >
      <Checkbox.Group
        defaultValue={['first', 'latest']}
        style={{ width: '100%' }}
      >
        <Row justify={'center'}>
          {oldAnswers[1] && (
            <Checkbox
              value="latest"
              checked={showLatestResult}
              onChange={(e) => {
                setShowLatestResult(e.target.checked);
              }}
            >
              前回結果の表示
            </Checkbox>
          )}
          {oldAnswers[0] && (
            <Checkbox
              value="first"
              checked={showFirstResult}
              onChange={(e) => {
                setShowFirstResult(e.target.checked);
              }}
            >
              初回結果の表示
            </Checkbox>
          )}
        </Row>
      </Checkbox.Group>
      <ResponsiveContainer
        width="100%"
        height={colSize === 'xs' ? 350 : colSize === 'sm' ? 400 : 500}
      >
        <RadarChart
          data={allData}
          margin={{
            top: 0,
            right: colSize === 'xs' ? 20 : 40,
            left: colSize === 'xs' ? 20 : 40,
            bottom: 0,
          }}
        >
          {(oldAnswers[0] || oldAnswers[1]) && <Legend verticalAlign="top" />}
          <PolarGrid />
          <PolarAngleAxis dataKey="subject" tick={customTick} />
          <PolarRadiusAxis
            angle={68}
            domain={[0, 2]}
            tickCount={3}
            tick={{ fill: '#8884d8', fontSize: 12 }}
          />
          <Radar
            name="今回"
            dataKey="thisScore"
            stroke="#E3672A"
            fill="#E3672A"
            fillOpacity={1}
            strokeWidth={2}
          />
          {showLatestResult && oldAnswers[1] && (
            <Radar
              name="前回"
              dataKey="latestScore"
              stroke="#09814A"
              fill="#09814A"
              fillOpacity={0}
              strokeWidth={3}
            />
          )}
          {showFirstResult && oldAnswers[0] && (
            <Radar
              name="初回"
              dataKey="firstScore"
              stroke="#03007E"
              strokeDasharray="3 3"
              fill="#03007E"
              fillOpacity={0}
              strokeWidth={3}
            />
          )}
        </RadarChart>
      </ResponsiveContainer>
    </Space.Compact>
  );
}
