import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App, ConfigProvider } from 'antd';
import reportWebVitals from './reportWebVitals';
import { ProvideAuth } from './hooks/useAuth';
import { QueryClient } from '@tanstack/query-core';
import { QueryClientProvider } from '@tanstack/react-query';
import { Routes } from './Routes';
import ReactGA from 'react-ga4';

const queryClient = new QueryClient();

const gaId = process.env.REACT_APP_GA_MEASUREMENT_ID; // 環境変数からGA測定IDを取得

if (gaId) {
  ReactGA.initialize(gaId); // Google Analyticsの初期化
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ProvideAuth>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#003288',
              colorLink: '#003288',
              fontSize: 15,
            },
          }}
        >
          <App>
            <Routes />
          </App>
        </ConfigProvider>
      </ProvideAuth>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
