import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { useMemo } from 'react';
import { OhatScore } from '@models/ohatScore';
import { Empty, Row } from 'antd';
import dayjs from 'dayjs';
import {
  getDateFromYear,
  getEndYearOf,
  getFirstDayOfMonth,
  getStartYearOf,
} from '../../utils/date';

export type Props = {
  scores: OhatScore[];
  year: string;
};
export const OhatScoresGraph = (props: Props) => {
  const { scores, year } = props;

  const scoresData = useMemo(() => {
    return scores.map((v) => {
      return {
        date: dayjs(v.evaluatedAt).valueOf(),
        score: v.q1 + v.q2 + v.q3 + v.q4 + v.q5 + v.q6 + v.q7 + v.q8,
      };
    });
  }, [scores]);

  // 現在の年の1月1日と12月31日のタイムスタンプ
  const startOfYear = getStartYearOf(getDateFromYear(year)).valueOf();
  const endOfYear = getEndYearOf(getDateFromYear(year)).valueOf();
  return (
    <Row style={{ height: 200, display: 'flex', justifyContent: 'center' }}>
      {scores.length === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={scoresData}
            margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              type="number"
              domain={[startOfYear, endOfYear]}
              tickFormatter={(tick) => dayjs(tick).format('M月')}
              ticks={[
                startOfYear,
                getFirstDayOfMonth(getDateFromYear(year), 2).valueOf(),
                getFirstDayOfMonth(getDateFromYear(year), 3).valueOf(),
                getFirstDayOfMonth(getDateFromYear(year), 4).valueOf(),
                getFirstDayOfMonth(getDateFromYear(year), 5).valueOf(),
                getFirstDayOfMonth(getDateFromYear(year), 6).valueOf(),
                getFirstDayOfMonth(getDateFromYear(year), 7).valueOf(),
                getFirstDayOfMonth(getDateFromYear(year), 8).valueOf(),
                getFirstDayOfMonth(getDateFromYear(year), 9).valueOf(),
                getFirstDayOfMonth(getDateFromYear(year), 10).valueOf(),
                getFirstDayOfMonth(getDateFromYear(year), 11).valueOf(),
                getFirstDayOfMonth(getDateFromYear(year), 12).valueOf(),
              ]}
              // tick={<CustomizedAxisTick />}
            />
            <YAxis type="number" domain={[0, 16]} includeHidden width={22} />
            <Tooltip
              labelFormatter={(label) => dayjs(label).format('MM/DD')} // ツールチップのラベルフォーマットを設定
            />
            <Line type="monotone" dataKey="score" stroke="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
      )}
    </Row>
  );
};
