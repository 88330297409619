import { useOrganizationFindAllSuspense } from '../../../libs/ohat-api';
import { Col, Row } from 'antd';
import { OrganizationsTypeTable } from '../../components/dashboard/OrganizationsTypeTable';
import { OrganizationsPrefectureTable } from '../../components/dashboard/OrganizationsPrefectureTable';

// メアド、名前、所属施設名、患者登録数、初回登録日、最終アクセス日
export const reportColumns = [
  {
    title: '施設名',
    dataIndex: 'orgName',
    key: 'orgName',
  },
  {
    title: '機関種別',
    dataIndex: 'orgType',
    key: 'orgType',
  },
  {
    title: '都道府県',
    dataIndex: 'orgPrefecture',
    key: 'orgPrefecture',
  },
  {
    title: '電話番号',
    dataIndex: 'orgPhoneNo',
    key: 'orgPhoneNo',
  },
  {
    title: '担当者名',
    dataIndex: 'accountName',
    key: 'accountName',
  },
  {
    title: 'メールアドレス',
    dataIndex: 'accountEmail',
    key: 'accountEmail',
  },
  {
    title: '患者数',
    dataIndex: 'patientsCount',
    key: 'patientsCount',
  },
  {
    title: '登録日',
    dataIndex: 'registeredAt',
    key: 'registeredAt',
  },
  {
    title: '最終アクセス日',
    dataIndex: 'lastActivityAt',
    key: 'lastActivityAt',
  },
];

export function OrganizationTypePage() {
  const { data } = useOrganizationFindAllSuspense();

  return (
    <Row gutter={[16, 8]} style={{ flex: 1, maxWidth: 1400 }}>
      {/* <Col md={24} xs={24}>
        <AdminOrganizationTypePageBreadCrumb />
      </Col> */}
      <Col md={12} xs={24}>
        <OrganizationsTypeTable organizations={data} />
      </Col>
      <Col md={12} xs={24}>
        <OrganizationsPrefectureTable organizations={data} />
      </Col>
    </Row>
  );
}
