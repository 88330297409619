import { Organization } from '@models/organization';
import { Progress, Table } from 'antd';
import { useMemo } from 'react';
import Column from 'antd/es/table/Column';
import { Prefecture } from '../../../constants/prefecture';

export type Props = {
  organizations: Organization[];
};
export const OrganizationsPrefectureTable = (props: Props) => {
  const { organizations } = props;

  const organizationPrefectureData = useMemo(() => {
    // 都道府県を集計
    const counts = organizations.reduce((acc, org) => {
      const { prefecture } = org;
      acc[prefecture] = (acc[prefecture] || 0) + 1;
      return acc;
    }, {} as { [key: string]: number });

    const total = Object.values(counts).reduce((sum, count) => sum + count, 0);

    const rates = Object.entries(counts).reduce((acc, [prefecture, count]) => {
      acc[prefecture] = Math.round((count / total) * 100);
      return acc;
    }, {} as { [key: string]: number });

    const aggregate = Object.values(Prefecture).map(
      (prefecture: Prefecture) => {
        return {
          key: prefecture,
          prefectureName: prefecture,
          prefectureCount: counts[prefecture] ?? 0,
          prefectureRate: rates[prefecture] ?? 0,
        };
      },
    );
    // rate順にソート
    // const sortedAggregate = aggregate.sort(
    //   (a, b) => b.prefectureCount - a.prefectureCount,
    // );
    return aggregate;
  }, [organizations]);

  return (
    <Table
      dataSource={organizationPrefectureData}
      size="small"
      bordered
      pagination={{
        pageSize: 50,
        hideOnSinglePage: true,
      }}
      className={'ant-aggregate-slim-table'}
    >
      <Column
        title="都道府県"
        dataIndex="prefectureName"
        key="prefectureName"
        width={'20%'}
        render={(val: string) => <p style={{ fontSize: 12 }}>{val}</p>}
      />
      <Column
        title="割合"
        dataIndex="prefectureRate"
        key="prefectureRate"
        render={(_val, record: any) => (
          <Progress
            percent={record['prefectureRate']}
            size={['90%', 15]}
            format={(p) => `${p}% (${record['prefectureCount']})`}
          />
        )}
      />
    </Table>
  );
};
