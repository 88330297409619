import { Form, Input, Button, Divider } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import useLoading from '../../../../hooks/useLoading';

export type Props = {
  onSubmit: (email: string) => void;
  failedCallback?: (info: any) => void;
};
const ResetPasswordForm = (props: Props) => {
  const { onSubmit, failedCallback = () => {} } = props;
  const { isLoading, startLoading, stopLoading } = useLoading();

  const onFinish = async (values: any) => {
    startLoading();
    await onSubmit(values.email);
    stopLoading();
  };

  const onFinishFailed = (errorInfo: any) => {
    failedCallback(errorInfo);
  };

  return (
    <Form
      name="resetPasswordForm"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      style={{ width: '100%' }}
      layout="vertical"
    >
      <Form.Item
        label="メールアドレス"
        name="email"
        rules={[
          { required: true, message: '必須入力です' },
          { type: 'email', message: '無効なメールアドレスです' },
        ]}
      >
        <Input size="large" prefix={<MailOutlined />} />
      </Form.Item>
      <Divider />
      <Form.Item>
        <Button
          size="large"
          type="primary"
          block
          htmlType="submit"
          loading={isLoading}
        >
          初期化
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ResetPasswordForm;
