// import timezone from 'dayjs/plugin/timezone';
// import utc from 'dayjs/plugin/utc';
// dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.tz.setDefault('Asia/Tokyo');
// export default dayjs;

import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';

dayjs.locale(ja);

export const getNowLabel = () => {
  const d = dayjs();
  return d.format('YYYY/MM/DD');
};

export const getNowDownloadLabel = () => {
  const d = dayjs();
  return d.format('YYYYMMDD_HHmm');
};

export const convertToJST = (date: Date): Date => {
  // GMTからJST（GMT+9時間）に変換
  return new Date(date.getTime() + 9 * 60 * 60 * 1000);
};

export const getNow = () => {
  return dayjs();
};

export const getNowISO = () => {
  return dayjs().toISOString();
};

export const getNowYear = () => {
  return getNow().format('YYYY');
};

export const getDateFromYear = (year: string) => {
  return dayjs(`${year}-01-01`);
};

export const getYesterday = (now: dayjs.Dayjs) => {
  return now.add(-1, 'D');
};
export const getNextYear = (now: dayjs.Dayjs) => {
  return now.add(1, 'year');
};

/**
 * 指定された年と月で最初の日を返す関数
 * @param {Dayjs} year - 年
 * @param {number} month - 月（1月は1、12月は12）
 * @return {Dayjs} - 指定された年月の最初の日のDayjsオブジェクト
 */
export const getFirstDayOfMonth = (year: dayjs.Dayjs, month: number) => {
  // Day.jsでは月が0から始まるため、month - 1を指定する
  return year.month(month - 1).date(1);
};

export const getStartYearOf = (date: dayjs.Dayjs) => {
  return date.startOf('year');
};

export const getEndYearOf = (date: dayjs.Dayjs) => {
  return date.endOf('year');
};

export const getDefaultPatientBirthYear = () => {
  return getNow().add(-80, 'year');
};

export const parseDate = (date: string | Date) => {
  return dayjs(date).toDate();
};

export const formatYYYYMMDD = (date: string | Date) => {
  return dayjs(date).format('YYYYMMDD');
};
export const format = (date: string | Date, format: string) => {
  return dayjs(date).format(format);
};

export const getAge = (birthDate: string) => {
  const now = getNow();
  const birth = dayjs(birthDate);
  const age = now.diff(birth, 'year');
  return age;
};
