import useMediaQuery from 'use-media-antd-query';

// xs: 480px以下（超小型デバイス、主にスマートフォン）
// sm: 576px以上（小型デバイス、小さめのタブレットや大きめのスマートフォン）
// md: 768px以上（中型デバイス、タブレット）
// lg: 992px以上（大型デバイス、デスクトップ）
// xl: 1200px以上（超大型デバイス、大きなデスクトップ）
// xxl: 1600px以上（超大型デバイス、非常に大きなデスクトップ）

export default function useMobile() {
  const colSize = useMediaQuery();
  const isMobile = () => {
    if (colSize === 'sm' || colSize === 'xs') return true;
    return false;
  };

  return { colSize, isMobile };
}
