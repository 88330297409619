import { Layout, Typography } from 'antd';
import { Content, Footer } from 'antd/es/layout/layout';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { useGAPageTracking } from '../../hooks/useGAPageTracking';
import { Suspense } from 'react';
import { Loading } from '../components/common/Loading';
import ErrorBoundary from '../components/common/ErrorBoundary';

export const GuestLayout = () => {
  useGAPageTracking();
  return (
    <Layout
      style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <ScrollRestoration />
      <Layout>
        <Content
          style={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#F5FAFF',
          }}
        >
          <ErrorBoundary>
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        </Content>
      </Layout>
      <Footer style={{ textAlign: 'center' }}>
        Copyright © メディバンクス株式会社 All Rights Reserved.
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
          <a
            href="https://www.ohat.jp/%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84"
            target="_blank"
            rel="noreferrer"
          >
            <Typography.Text
              style={{
                paddingTop: 4,
                fontSize: 14,
                textAlign: 'center',
                textDecorationLine: 'underline',
              }}
            >
              利用規約
            </Typography.Text>
          </a>
          <a
            href="https://medi-banx.com/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            <Typography.Text
              style={{
                paddingTop: 4,
                fontSize: 14,
                textAlign: 'center',
                textDecorationLine: 'underline',
              }}
            >
              個人情報の取り扱い
            </Typography.Text>
          </a>
          <a
            href="https://medi-banx.com/contact/"
            target="_blank"
            rel="noreferrer"
          >
            <Typography.Text
              style={{
                paddingTop: 4,
                fontSize: 14,
                textAlign: 'center',
                textDecorationLine: 'underline',
              }}
            >
              お問い合わせ
            </Typography.Text>
          </a>
        </div>
      </Footer>
    </Layout>
  );
};
