import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { App, Card, Flex } from 'antd';
import VerifyResetPasswordForm from './form/VerifyResetPasswordForm';
import { useEffect } from 'react';

export function VerifyResetPasswordPage() {
  const auth = useAuth();
  const { message } = App.useApp();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  useEffect(() => {
    if (auth.isAuthenticated) auth.signOutCognito();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (email: string, newPassword: string) => {
    if (!code) {
      message.error('URLの形式が不正です');
      return;
    }
    try {
      await auth.confirmResetPasswordCognito(email, code, newPassword);
      const sub = await auth.signInCognito(email, newPassword);
      await auth.fetchAccount(sub);
      window.location.href = '/';
    } catch (e) {
      message.error('パスワード初期化に失敗しました');
      console.error(e);
    }
  };

  return (
    <Flex
      vertical
      style={{
        maxWidth: 500,
      }}
      gap={0}
    >
      <img
        src={`${process.env.PUBLIC_URL}/logo.svg`}
        alt="Logo"
        style={{ alignSelf: 'center', marginBottom: 25 }}
      />
      <Card title="パスワードリセット" style={{ minWidth: 320 }}>
        <VerifyResetPasswordForm onSubmit={onSubmit} />
      </Card>
    </Flex>
  );
}
