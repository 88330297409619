import { useAuth } from '../../../hooks/useAuth';
import { Navigate, Outlet } from 'react-router-dom';
import ReactGA from 'react-ga4';

export const PrivateRoute: React.FC = () => {
  const { isAuthenticated, account } = useAuth();
  if (isAuthenticated && account) {
    ReactGA.set({ userId: account.id });
  }

  if (account && account.isAdmin) return <Navigate to="/admin" />;
  return isAuthenticated ? <Outlet /> : <Navigate to="/signin" />;
};
