import { BaseConstants } from './baseConstants';

export class PatientCareLevel extends BaseConstants {
  static readonly none = new PatientCareLevel('none', 'なし');
  static readonly help1 = new PatientCareLevel('help1', '要支援1');
  static readonly help2 = new PatientCareLevel('help2', '要支援2');
  static readonly care1 = new PatientCareLevel('care1', '要介護1');
  static readonly care2 = new PatientCareLevel('care2', '要介護2');
  static readonly care3 = new PatientCareLevel('care3', '要介護3');
  static readonly care4 = new PatientCareLevel('care4', '要介護4');
  static readonly care5 = new PatientCareLevel('care5', '要介護5');

  private constructor(
    public readonly key: string,
    public readonly label: string,
  ) {
    super();
  }

  toString() {
    return this.key;
  }
}
