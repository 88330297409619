import { useState } from 'react';

export default function useLoading() {
  const [isLoading, setIsLoading] = useState(false);
  const startLoading = () => {
    setIsLoading(true);
  };
  const stopLoading = () => {
    setIsLoading(false);
  };

  return { isLoading, startLoading, stopLoading };
}
