import { BaseConstants } from './baseConstants';
import { Choice } from './choice';
import q10 from '../static/questions/q1-0.jpg';
import q11 from '../static/questions/q1-1.jpg';
import q12 from '../static/questions/q1-2.jpg';
import q20 from '../static/questions/q2-0.jpg';
import q21 from '../static/questions/q2-1.jpg';
import q22 from '../static/questions/q2-2.jpg';
import q30 from '../static/questions/q3-0.jpg';
import q31 from '../static/questions/q3-1.jpg';
import q32 from '../static/questions/q3-2.jpg';
import q40 from '../static/questions/q4-0.jpg';
import q41 from '../static/questions/q4-1.jpg';
import q42 from '../static/questions/q4-2.jpg';
import q50 from '../static/questions/q5-0.jpg';
import q51 from '../static/questions/q5-1.jpg';
import q52 from '../static/questions/q5-2.jpg';
import q60 from '../static/questions/q6-0.jpg';
import q61 from '../static/questions/q6-1.jpg';
import q62 from '../static/questions/q6-2.jpg';
import q70 from '../static/questions/q7-0.jpg';
import q71 from '../static/questions/q7-1.jpg';
import q72 from '../static/questions/q7-2.jpg';
import q80 from '../static/questions/q8-0.jpg';
import q81 from '../static/questions/q8-1.jpg';
import q82 from '../static/questions/q8-2.jpg';

import { OhatStatus } from './status';

export class Question extends BaseConstants {
  static readonly Q1 = new Question('Q1', '口唇', [
    new Choice(OhatStatus.fine, q10, ['正常', '湿潤', 'ピンク']),
    new Choice(OhatStatus.bad, q11, ['乾燥', 'ひび割れ', '口角の発赤']),
    new Choice(OhatStatus.diseased, q12, [
      '腫脹や腫瘤',
      '赤色斑, 白色斑',
      '潰瘍性出血',
      '口角の出血, 潰瘍',
    ]),
  ]);

  static readonly Q2 = new Question('Q2', '舌', [
    new Choice(OhatStatus.fine, q20, ['正常', '湿潤', 'ピンク']),
    new Choice(OhatStatus.bad, q21, ['不整', '亀裂', '発赤', '舌苔の付着']),
    new Choice(OhatStatus.diseased, q22, ['赤色斑', '白色斑', '潰瘍', '腫脹']),
  ]);

  static readonly Q3 = new Question('Q3', '歯肉・粘膜', [
    new Choice(OhatStatus.fine, q30, ['正常', '湿潤', 'ピンク']),
    new Choice(OhatStatus.bad, q31, [
      '乾燥, 光沢',
      '粗造, 発赤',
      '部分的(1~6歯分)腫脹',
      '義歯下の一部潰瘍',
    ]),
    new Choice(OhatStatus.diseased, q32, [
      '腫脹, 出血（7歯分以上）',
      '歯の動揺, 潰瘍',
      '白色斑, 発赤, 圧痛',
    ]),
  ]);

  static readonly Q4 = new Question('Q4', '唾液', [
    new Choice(OhatStatus.fine, q40, ['湿潤', '漿液性']),
    new Choice(OhatStatus.bad, q41, [
      '乾燥',
      'べたつく粘膜',
      '少量の唾液',
      '口渇感若干あり',
    ]),
    new Choice(OhatStatus.diseased, q42, [
      '赤く干からびた状態',
      '唾液はほぼなし',
      '粘性の高い唾液',
      '口渇感あり',
    ]),
  ]);

  static readonly Q5 = new Question('Q5', '残存歯', [
    new Choice(OhatStatus.fine, q50, ['歯・歯根のう蝕または破折なし']),
    new Choice(OhatStatus.bad, q51, ['３本以下のう蝕, 歯の破折, 残根, 咬耗']),
    new Choice(OhatStatus.diseased, q52, [
      '４歯以上のう蝕, 歯の破折, 残根',
      '非常に強い咬耗',
      '義歯使用無しで3本以下の残存歯',
    ]),
  ]);

  static readonly Q6 = new Question('Q6', '義歯', [
    new Choice(OhatStatus.fine, q60, [
      '正常',
      '義歯, 人工歯の破折なし',
      '普通に装着できる状態',
    ]),
    new Choice(OhatStatus.bad, q61, [
      '1部位の義歯, 人工歯の破折',
      '毎日1〜2時間装着のみ可能',
    ]),
    new Choice(OhatStatus.diseased, q62, [
      '2部位以上の義歯, 人工歯の破折',
      '義歯紛失, 義歯不適合のため未装着',
      '義歯接着剤が必要',
    ]),
  ]);

  //
  //
  static readonly Q7 = new Question('Q7', '口腔清掃', [
    new Choice(OhatStatus.fine, q70, ['食渣, 歯石, プラークの付着がない']),
    new Choice(OhatStatus.bad, q71, [
      '1〜2部位に食渣, 歯石, プラークあり',
      '若干口臭あり',
    ]),
    new Choice(OhatStatus.diseased, q72, [
      '多くの部位に食渣, 歯石, プラークあり',
      '強い口臭あり',
    ]),
  ]);

  static readonly Q8 = new Question('Q8', '歯痛', [
    new Choice(OhatStatus.fine, q80, ['疼痛を示す言動的, 身体的な兆候なし']),
    new Choice(OhatStatus.bad, q81, [
      '疼痛を示す言動的な兆候あり',
      '顔を引きつらせる, 口唇を噛む',
      '食事しない, 攻撃的になる',
    ]),
    new Choice(OhatStatus.diseased, q82, [
      '疼痛を示す身体的な兆候あり',
      '頬や歯肉の腫脹, 歯の破折, 潰瘍, 歯肉下膿瘍',
      '言動的な兆候もあり',
    ]),
  ]);

  private constructor(
    public readonly key: string, // Q1
    public readonly label: string, // 口唇
    public readonly choices: Choice[], // 口唇
  ) {
    super();
  }

  toString() {
    return this.key;
  }
}
export const ALL_QUESTIONS = [
  Question.Q1,
  Question.Q2,
  Question.Q3,
  Question.Q4,
  Question.Q5,
  Question.Q6,
  Question.Q7,
  Question.Q8,
];
export type StepIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
