import {
  usePatientFindOneSuspense,
  usePatientUpdate,
} from '../../../libs/ohat-api';
import { Card, Col, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdatePatientDto } from '@models/updatePatientDto';
import { PatientForm } from './form/PatientForm';
import { CreatePatientDto } from '@models/createPatientDto';

export function UpdatePatientPage() {
  const param = useParams();
  const navigate = useNavigate();
  const { data } = usePatientFindOneSuspense(param.id ?? '');
  const { mutateAsync: updatePatient } = usePatientUpdate();

  const onSubmit = async (values: CreatePatientDto | UpdatePatientDto) => {
    await updatePatient({ id: param.id!, data: values });
    navigate(`/patients/${param.id}`);
  };

  return (
    <Row gutter={[16, 8]} style={{ flex: 1, justifyContent: 'center' }}>
      <Col lg={8} md={12} sm={16} xs={24}>
        <Card title="患者修正">
          <PatientForm
            initialValue={data}
            onSubmit={onSubmit}
            onCancel={() => navigate(`/patients/${param.id}`)}
          />
        </Card>
      </Col>
    </Row>
  );
}
