import { Button, Result } from 'antd';

export function NotFoundPage() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="アクセスしたページは表示できませんでした"
      extra={
        <Button type="primary" size="large" href="/">
          Topに戻る
        </Button>
      }
    />
  );
}
