import { useEffect } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { Card, Flex } from 'antd';
import Typography from 'antd/es/typography/Typography';
import { useNavigate } from 'react-router-dom';

export function SignOutPage() {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    auth.signOutCognito();
    navigate({ pathname: '/signin' });
  }, [auth, navigate]);

  return (
    <Flex
      vertical
      style={{
        maxWidth: 500,
      }}
      gap={0}
    >
      <img
        src={`${process.env.PUBLIC_URL}/logo.svg`}
        alt="Logo"
        style={{ alignSelf: 'center', marginBottom: 25 }}
      />
      <Card title="サインアウト" style={{ minWidth: 320 }}>
        <Typography>サインアウト中です</Typography>
      </Card>
    </Flex>
  );
}
