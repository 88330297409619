import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { useAccountCreate } from '../../../libs/ohat-api';
import { App, Button, Card, Flex } from 'antd';
import { SignInForm } from './form/SignInForm';
import { useGAEvent } from '../../../hooks/useGAEvent';

export function SignUpPage() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { mutateAsync: createAccount } = useAccountCreate();
  const { message } = App.useApp();
  const { sendClickEvent } = useGAEvent();

  useEffect(() => {
    if (auth.isAuthenticated && !!auth.account) navigate({ pathname: '/' });
  }, [auth, navigate]);

  const onSubmit = async (email: string, password: string) => {
    sendClickEvent('会員登録確定');
    try {
      await auth.signUpCognito(email, password);
      const sub = await auth.signInCognito(email, password);
      const apiResult = await createAccount({
        data: {
          email,
          sub,
        },
      });
      if (apiResult) {
        await auth.fetchAccount(sub);
        navigate({ pathname: '/' });
      } else {
        throw Error('network error');
      }
    } catch (e) {
      console.error(e);
      message.error('ユーザ登録に失敗しました');
      await auth.signOutCognito();
    }
  };

  return (
    <Flex
      vertical
      style={{
        maxWidth: 500,
      }}
      gap={0}
    >
      <img
        src={`${process.env.PUBLIC_URL}/logo.svg`}
        alt="Logo"
        style={{ alignSelf: 'center', marginBottom: 25 }}
      />
      <Card title="新規登録" style={{ minWidth: 320 }}>
        <SignInForm
          onSubmit={onSubmit}
          buttonText="新規登録"
          showConfirm={true}
        />
        <Button size="large" onClick={() => navigate({ pathname: '/' })} block>
          キャンセル
        </Button>
      </Card>
    </Flex>
  );
}
