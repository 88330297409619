import { BaseConstants } from '../constants/baseConstants';

export const getEnumOptions = (
  enumValue: any,
): { key: string; value: string; label: string }[] => {
  return Object.values(enumValue).map((value) => {
    return {
      key: value as string,
      value: value as string,
      label: value as string,
    };
  });
};

export const getBaseConstantsOptions = (
  constants: BaseConstants[],
): { key: string; value: string; label: string }[] => {
  return constants.map((value) => {
    return {
      key: value.key as string,
      value: value.key as string,
      label: value.label as string,
    };
  });
};
