import {
  useAccountFindOneSuspense,
  useAccountUpdate,
  useOrganizationFindOneSuspense,
  useOrganizationUpdate,
} from '../../../libs/ohat-api';
import { Card, Col, Row } from 'antd';
import { CreateOrganizationDto } from '@models/createOrganizationDto';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdateOrganizationDto } from '@models/updateOrganizationDto';
import { OrganizationForm } from './form/OrganizationForm';
import { UpdateAccountDto } from '@models/updateAccountDto';
import { useAuth } from '../../../hooks/useAuth';

export function UpdateOrganizationPage() {
  const param = useParams();
  const auth = useAuth();
  const navigate = useNavigate();
  const { data: org } = useOrganizationFindOneSuspense(param.id ?? '');
  const { data: account } = useAccountFindOneSuspense(auth.account!.sub ?? '');
  const { mutateAsync: updateOrganization } = useOrganizationUpdate();
  const { mutateAsync: updateAccount } = useAccountUpdate();

  const onSubmit = async (
    orgValues: CreateOrganizationDto | UpdateOrganizationDto,
    accountValues: UpdateAccountDto,
  ) => {
    await updateOrganization({ id: param.id!, data: orgValues });
    await updateAccount({ id: account!.id, data: accountValues });
    await auth.fetchAccount(account!.sub);
    navigate('/');
  };

  return (
    <Row gutter={[16, 8]} style={{ flex: 1, justifyContent: 'center' }}>
      <Col lg={8} md={12} sm={16} xs={24}>
        <Card title="事業所更新">
          <OrganizationForm
            initialValue={{
              org: org!,
              account: account!,
            }}
            onSubmit={onSubmit}
            onCancel={() => navigate(`/`)}
          />
        </Card>
      </Col>
    </Row>
  );
}
