import { Answers } from './../views/components/check/OhatResultGraph';
import { OhatScore } from '@models/ohatScore';
import { OhatStatus } from '../constants/status';
import { format, getNowLabel } from './date';

// export const initAnswers: Answers = {
//   score: {
//     0: -1,
//     1: -1,
//     2: -1,
//     3: -1,
//     4: -1,
//     5: -1,
//     6: -1,
//     7: -1,
//   },
//   evaluatedAt: getNowLabel(),
// };

export const getInitAnswers = (): Answers => {
  return {
    score: {
      0: -1,
      1: -1,
      2: -1,
      3: -1,
      4: -1,
      5: -1,
      6: -1,
      7: -1,
    },
    evaluatedAt: getNowLabel(),
  };
};

export const extractAnswers = (score: OhatScore): Answers => {
  return {
    score: {
      0: score.q1,
      1: score.q2,
      2: score.q3,
      3: score.q4,
      4: score.q5,
      5: score.q6,
      6: score.q7,
      7: score.q8,
    },
    evaluatedAt: format(score.evaluatedAt, 'YYYY/MM/DD'),
  };
};

export const getScoreTableRow = (key: string, answers: Answers) => {
  return {
    'key': key,
    '0': OhatStatus.getByScore(answers.score[0])?.label,
    '1': OhatStatus.getByScore(answers.score[1])?.label,
    '2': OhatStatus.getByScore(answers.score[2])?.label,
    '3': OhatStatus.getByScore(answers.score[3])?.label,
    '4': OhatStatus.getByScore(answers.score[4])?.label,
    '5': OhatStatus.getByScore(answers.score[5])?.label,
    '6': OhatStatus.getByScore(answers.score[6])?.label,
    '7': OhatStatus.getByScore(answers.score[7])?.label,
  };
};

export const getScoreTableRowHalf1 = (key: string, answers: Answers) => {
  return {
    'key': key,
    '0': OhatStatus.getByScore(answers.score[0])?.label,
    '1': OhatStatus.getByScore(answers.score[1])?.label,
    '2': OhatStatus.getByScore(answers.score[2])?.label,
    '3': OhatStatus.getByScore(answers.score[3])?.label,
  };
};

export const getScoreTableRowHalf2 = (key: string, answers: Answers) => {
  return {
    'key': key,
    '0': OhatStatus.getByScore(answers.score[4])?.label,
    '1': OhatStatus.getByScore(answers.score[5])?.label,
    '2': OhatStatus.getByScore(answers.score[6])?.label,
    '3': OhatStatus.getByScore(answers.score[7])?.label,
  };
};

export const defaultScoreTableRow = (key: string) => {
  return {
    'key': key,
    '0': 0,
    '1': 0,
    '2': 0,
    '3': 0,
    '4': 0,
    '5': 0,
    '6': 0,
    '7': 0,
  };
};
