import { useMemo, useState } from 'react';
import OhatResultGraph, { Answers } from './OhatResultGraph';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Button, Col, Flex, Row, Typography } from 'antd';
import { DownloadOutlined, UserOutlined } from '@ant-design/icons';
import OhatResultTable from './OhatResultTable';
import { getNowDownloadLabel } from '../../../utils/date';
import { timeout } from '../../../utils/system';
import { Patient } from '@models/patient';
import { useNavigate } from 'react-router-dom';
import { IndependentLifeLevel } from '../../../constants/independentLifeLevel';
import { PatientCareLevel } from '../../../constants/careLevel';
import { Gender } from '../../../constants/gender';
import useMobile from '../../../hooks/useMobile';
import OhatResultVerticalTable from './OhatResultVerticalTable';
import { useGAEvent } from '../../../hooks/useGAEvent';
import { OhatScore } from '@models/ohatScore';
import { extractAnswers } from '../../../utils/ohat-score-utils';

type Props = {
  answers: Answers;
  isGuestMode: boolean;
  // 以下は、ゲストモードだと値はない
  oldOhatScores: OhatScore[];
  patient?: Patient;
};
export default function OhatResultPanel(props: Props) {
  const { answers, isGuestMode, patient, oldOhatScores } = props;
  const [isDownloading, setIsDownloading] = useState(false);
  const { isMobile } = useMobile();
  const { sendClickEvent } = useGAEvent();
  const navigate = useNavigate();
  const lifeLevel = patient
    ? IndependentLifeLevel.getByKey(patient.independentLifeLevel)
    : IndependentLifeLevel.none;
  const careLevel = patient
    ? PatientCareLevel.getByKey(patient.careLevel)
    : PatientCareLevel.none;

  const oldAnswers = useMemo(() => {
    return oldOhatScores.map((score: OhatScore) => extractAnswers(score));
  }, [oldOhatScores]);

  const pdhDownloadHandler = async () => {
    sendClickEvent('PDFダウンロード');

    const target = document.getElementById('pdf-id');
    if (target === null) return;
    setIsDownloading(true);
    await timeout(10);

    html2canvas(target, {
      useCORS: true,
      allowTaint: true,
      scrollY: -window.scrollY,
    }).then((canvas) => {
      const image = canvas.toDataURL('image/jpeg', 1.0);
      const doc = new jsPDF('p', 'px', 'a4');
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();

      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;

      const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = (pageHeight - canvasHeight) / 2;

      doc.addImage(image, 'JPEG', marginX, marginY, canvasWidth, canvasHeight);
      doc.save(`ohat_${getNowDownloadLabel()}.pdf`);
      setIsDownloading(false);
    });
  };
  const getScore = (answers: Answers) => {
    const score = Object.values(answers.score).reduce((a, v) => {
      return a + v;
    }, 0);
    return score > 0 ? score : 0;
  };

  const onClick = () => {
    navigate(-1); // navigate(`/patients/${patient!.id}`);
  };

  const onClickRegister = () => {
    sendClickEvent('会員登録');
    navigate(`/signup`);
  };

  return (
    <Row gutter={[16, 8]} justify="center" style={{ maxWidth: 800 }}>
      <Col span={24} id="pdf-id">
        <Flex vertical gap={0} justify="center">
          <Typography.Title
            level={2}
            underline
            style={{
              textAlign: 'center',
            }}
          >{`評価結果`}</Typography.Title>
          <Typography.Text
            style={{
              fontSize: 30,
              fontWeight: 400,
              color: '#03007E',
              textAlign: 'center',
            }}
          >{`OHATスコア: ${getScore(answers)}pt`}</Typography.Text>
          {!isGuestMode && patient && (
            <>
              <Typography.Text style={{ fontSize: 22, textAlign: 'center' }}>
                {patient.age
                  ? `${patient.name}(${patient.age}) ${
                      Gender.getByKey(patient.gender).label
                    }`
                  : `${patient.name} ${Gender.getByKey(patient.gender).label}`}
              </Typography.Text>
              <Typography.Text style={{ fontSize: 16, textAlign: 'center' }}>
                {`${
                  lifeLevel === IndependentLifeLevel.none ? '' : lifeLevel.label
                } ${
                  careLevel === PatientCareLevel.none ? '' : careLevel.label
                }`}
              </Typography.Text>
            </>
          )}
          <Typography.Text
            type="secondary"
            style={{ textAlign: 'center' }}
          >{`評価日 ${answers.evaluatedAt}`}</Typography.Text>
        </Flex>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <OhatResultGraph answers={answers} oldAnswers={oldAnswers} />
        </Col>
        <Row
          gutter={[16, 9]}
          justify="center"
          style={{ marginBottom: 10, marginTop: 15 }}
        >
          <Col lg={20} md={24} sm={24}>
            {isMobile() ? (
              <OhatResultVerticalTable
                answers={answers}
                oldAnswers={oldAnswers}
              />
            ) : (
              <OhatResultTable answers={answers} oldAnswers={oldAnswers} />
            )}

            <Typography.Text style={{ color: '#a9a9a9', fontSize: 12 }}>
              「不良」「病的」に該当する場合は、ご本人の不快感などの訴えがない場合でも、歯科医療機関への受診を勧めてください。
            </Typography.Text>
          </Col>
        </Row>
        {isDownloading && (
          <Row
            gutter={[16, 16]}
            justify="center"
            style={{ paddingTop: 25, paddingBottom: 25 }}
          >
            <div
              style={{
                height: 200,
                width: '90%',
                border: '1px solid gray',
              }}
            >
              <Typography.Text style={{ marginTop: 10, marginLeft: 10 }}>
                備考
              </Typography.Text>
            </div>
          </Row>
        )}
      </Col>
      {isGuestMode && (
        <Col
          xs={24}
          md={24}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              // background: '#a9a9a9',
              padding: 20,
              borderRadius: 8,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Button
              type="primary"
              size="large"
              shape="round"
              onClick={onClickRegister}
              style={{
                paddingTop: 12,
                paddingBottom: 50,
              }}
            >
              <>
                <UserOutlined
                  style={{ fontSize: 26, color: 'white', marginRight: 8 }}
                />
                <Typography.Text style={{ fontSize: 26, color: 'white' }}>
                  会員登録する
                </Typography.Text>
              </>
            </Button>
            <Typography.Text style={{ fontSize: 16, marginTop: 20 }}>
              会員登録をすると、複数の患者のOHAT管理ができるようになります。また患者毎に評価の履歴が残り、自動的にグラフ化される為、状態の変化を視覚的に管理することができます。
            </Typography.Text>
          </div>
        </Col>
      )}

      <Col
        xs={24}
        md={10}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Button
          type="default"
          size="large"
          shape="round"
          loading={isDownloading}
          icon={<DownloadOutlined />}
          onClick={pdhDownloadHandler}
          block
        >
          結果ダウンロード
        </Button>
      </Col>

      <Col
        xs={24}
        md={10}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Button size="large" shape="round" onClick={onClick} block>
          戻る
        </Button>
      </Col>
      <Col span={24} style={{ marginTop: 20 }}></Col>
    </Row>
  );
}
