import { Layout, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';

export const Loading = () => {
  return (
    <Layout
      style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Layout>
        <Content
          style={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin size="large"></Spin>
        </Content>
      </Layout>
    </Layout>
  );
};
