import { Form, Input, Button, Select } from 'antd';
import { getEnumOptions } from '../../../../utils/option-utils';
import { Prefecture } from '../../../../constants/prefecture';
import { useEffect, useMemo } from 'react';
import { CreateOrganizationDto } from '@models/createOrganizationDto';
import { UpdateOrganizationDto } from '@models/updateOrganizationDto';
import { Organization } from '@models/organization';
import { OrganizationType } from '../../../../constants/orgType';
import { UpdateAccountDto } from '@models/updateAccountDto';
import { Account } from '@models/account';
import useLoading from '../../../../hooks/useLoading';

type FormValuesType =
  | (CreateOrganizationDto & {
      accountName: string;
    })
  | (UpdateOrganizationDto & {
      accountName: string;
    });

export type Props = {
  onSubmit: (
    orgValues: CreateOrganizationDto | UpdateOrganizationDto,
    accountValues: UpdateAccountDto,
  ) => void;
  onCancel?: () => void;
  initialValue?: { org: Organization; account: Account };
  failedCallback?: (info: any) => void;
};
export const OrganizationForm = (props: Props) => {
  const { onSubmit, onCancel, initialValue, failedCallback = () => {} } = props;
  const [form] = Form.useForm();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const prefectureOptions = useMemo(() => getEnumOptions(Prefecture), []);

  const onFinish = async ({ accountName, ...orgValues }: FormValuesType) => {
    startLoading();
    await onSubmit(orgValues, { name: accountName });
    stopLoading();
  };

  useEffect(() => {
    if (initialValue) {
      form.resetFields();
      form.setFieldsValue({
        name: initialValue.org.name,
        phoneNo: initialValue.org.phoneNo,
        prefecture: initialValue.org.prefecture,
        type: initialValue.org.type,
        accountName: initialValue.account.name,
      });
    }
  }, [form, initialValue]);

  const onFinishFailed = (errorInfo: any) => {
    failedCallback(errorInfo);
  };
  // 郵便番号入力時のハンドラ
  // const findZipCode = useCallback(async () => {
  //   const zipCode = form.getFieldValue('zipCode');
  //   form
  //     .validateFields(['zipCode'])
  //     .then(async () => {
  //       const res = await axios.get(ZIP_CODE_SEARCH_URL, {
  //         params: { zipcode: zipCode },
  //       });
  //       if (res.data.status === 200 && res.data.results[0]) {
  //         const data = res.data.results[0];
  //         const addressInfo = {
  //           prefecture: data['address1'],
  //           city: `${data['address2']}${data['address3']}`,
  //         };
  //         form.setFieldsValue(addressInfo);
  //       }
  //     })
  //     .catch((_) => {});
  // }, [form]);

  return (
    <Form
      form={form}
      name="organizationForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      style={{ width: '100%' }}
      layout="vertical"
    >
      <Form.Item
        label="所属機関種別"
        name="type"
        rules={[{ required: true, message: '必須入力です' }]}
      >
        <Select
          showSearch
          placeholder="所属機関種別を選択"
          optionFilterProp="children"
        >
          {OrganizationType.getAll().map((opt) => (
            <Select.Option key={opt.key} value={opt.key}>
              {opt.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="都道府県"
        name="prefecture"
        rules={[{ required: true, message: '必須入力です' }]}
      >
        <Select
          showSearch
          placeholder="都道府県を選択"
          optionFilterProp="children"
        >
          {prefectureOptions.map((opt) => (
            <Select.Option key={opt.key} value={opt.value}>
              {opt.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="事業所名称" name="name" initialValue={''}>
        <Input />
      </Form.Item>

      <Form.Item label="担当者名" name="accountName" initialValue={''}>
        <Input />
      </Form.Item>
      {/* <Form.Item label="郵便番号" required={true}>
        <Row gutter={8}>
          <Col flex={2}>
            <Form.Item
              name="zipCode"
              noStyle
              rules={[
                { required: true, message: '郵便番号を入力してください' },
                {
                  pattern: /^[0-9]{7}$/,
                  message: '7桁の数字を入力してください',
                },
              ]}
            >
              <Input maxLength={7} />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Button icon={<SearchOutlined />} onClick={findZipCode}>
              検索
            </Button>
          </Col>
        </Row>
      </Form.Item> */}

      <Form.Item
        label="電話番号"
        name="phoneNo"
        rules={[
          {
            pattern: /^\d{9,11}$/,
            message: '9-11桁の数字を入力してください',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button
          size="large"
          shape="round"
          type="primary"
          block
          htmlType="submit"
          loading={isLoading}
        >
          送信
        </Button>
        {onCancel && (
          <Button
            size="large"
            shape="round"
            block
            style={{ marginTop: 12 }}
            onClick={onCancel}
          >
            キャンセル
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};
